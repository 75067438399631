export enum UserActionsTypes {
  USER_INITIALIZE = 'USER_INITIALIZE',
  USER_INITIALIZE_SUCCESS = 'USER_INITIALIZE_SUCCESS',
  USER_INITIALIZE_FAIL = 'USER_INITIALIZE_FAIL',

  USER_GET_SUBSCRIPTIONS = 'USER_GET_SUBSCRIPTIONS',
  USER_GET_SUBSCRIPTIONS_SUCCESS = 'USER_GET_SUBSCRIPTIONS_SUCCESS',
  USER_GET_SUBSCRIPTIONS_FAIL = 'USER_GET_SUBSCRIPTIONS_FAIL',

  USER_GET_BALANCE = 'USER_GET_BALANCE',
  USER_GET_BALANCE_SUCCESS = 'USER_GET_BALANCE_SUCCESS',
  USER_GET_BALANCE_FAIL = 'USER_GET_BALANCE_FAIL',

  USER_GET_OVERVIEW = 'USER_GET_OVERVIEW',
  USER_GET_OVERVIEW_SUCCESS = 'USER_GET_OVERVIEW_SUCCESS',
  USER_GET_OVERVIEW_FAIL = 'USER_GET_OVERVIEW_FAIL',

  USER_SET_TEMP_FIRST_NAME = 'USER_SET_TEMP_FIRST_NAME',
  USER_SET_TEMP_LAST_NAME = 'USER_SET_TEMP_LAST_NAME',

  USER_SET_FIRST_NAME = 'USER_SET_FIRST_NAME',
  USER_SET_LAST_NAME = 'USER_SET_LAST_NAME',
  USER_SET_EMAIL = 'USER_SET_EMAIL',

  USER_GET_SCRAPPER_API_KEY = 'USER_GET_SCRAPPER_API_KEY',
  USER_GET_SCRAPPER_API_KEY_SUCCESS = 'USER_GET_SCRAPPER_API_KEY_SUCCESS',
  USER_GET_SCRAPPER_API_KEY_FAIL = 'USER_GET_SCRAPPER_API_KEY_FAIL',

  USER_GET_PROXY_PACKAGES = 'USER_GET_PROXY_PACKAGES',
  USER_GET_PROXY_PACKAGES_SUCCESS = 'USER_GET_PROXY_PACKAGES_SUCCESS',
  USER_GET_PROXY_PACKAGES_FAIL = 'USER_GET_PROXY_PACKAGES_FAIL',
}
