import React from 'react'
import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'

import styles from './HomeContent.module.scss'

import SubscriptionInfo from 'components/modules/settings/SubscriptionsInfo'
import PromoBlock from 'components/elements/blocks/PromoBlock'
import ProductCard from 'components/modules/pricing/ProductCard'
/*import {
  FolderOpen as FolderIcon,
  Server as ServerIcon,
} from 'components/icons'*/

import { selectSubscriptionInfo } from 'store/user/selectors'
import { workingSubscriptionStatuses } from 'utils/constants'

/*const trialPromoItems = [
  {
    label: 'Scraping APIs',
    icon: <FolderIcon />,
  },
  {
    label: 'Proxies',
    icon: <ServerIcon />,
  },
]*/

const products = [
  {
    title: 'Scraping APIs',
    description: (
      <div>
        <p>Effortless data extraction</p>
      </div>
    ),
    /*prices: [
      'eCommerce $2.2 per 1k req.',
      'Social $3.1 per 1k req.',
      'Business review $5.4 per 1k req.',
      'SERP $1.6 per 1k req.',
      'more APIs come soon',
    ],*/
    link: '/scraping',
  },
  {
    title: 'Proxies',
    description: 'Flexible proxy setup',
    /*prices: ['Residential', 'Mobile', 'DC', 'ISP', 'Single GEO'],*/
    link: '/proxy',
  },
]

const HomeContent: React.FC = () => {
  const { show } = useIntercom()

  const subscriptionInfo = useSelector(selectSubscriptionInfo)

  const handleSupport = () => {
    show()
  }

  const productsList = products.map((product, index) => (
    <ProductCard
      product={product}
      isFullFunctional={
        workingSubscriptionStatuses.indexOf(subscriptionInfo?.status) >= 0
      }
      className={styles.productCard}
      key={`home-page-product-card_${index}`}
    />
  ))

  return (
    <div className={styles.container}>
      {!subscriptionInfo?.plan && (
        <PromoBlock
          title={'Welcome to new SOAX'}
          image={`${process.env.PUBLIC_URL}/ball.png`}
          className={styles.block}
        >
          Congratulations! You’ve been granted early access to our new dashboard
          beta, featuring the “Pay as you go” plan and our new Scraping API
          product. Our Customer Care team is{' '}
          <span onClick={handleSupport} className={styles.supportLink}>
            here to assist you
          </span>{' '}
          at any time!
        </PromoBlock>
      )}

      <SubscriptionInfo />

      <div>
        {!!subscriptionInfo?.plan && (
          <h3 className={styles.productsTitle}>Launch your SOAX products</h3>
        )}
        <div className={styles.row}>{productsList}</div>
      </div>
    </div>
  )
}

export default HomeContent
