import { toast } from 'react-toastify'

// return true if validate error received
export function handle(data, e, flash = true) {
  let message = ''

  if (e.data) {
    if (e.data.message && e.data.message === 'Internal server error') {
      message = 'Internal server error, please try again later in a few minutes'
    } else if (e.data.message) {
      message = e.data.message
    } else if (e.data.error) {
      message = `Error: ${e.data.error}`
    }
  } else if (typeof e === 'string') {
    message = e
  } else {
    message = 'An error occurred, please try again later in a few minutes'
  }

  if (e.data) {
    if (e.data.statusCode >= 500) {
      toast.error(message)
      console.error(message)
    } else if (e.data.statusCode !== 404 && e.status !== 404) {
      toast.warn(message)
      console.warn(message)
    }
  } else {
    toast.error(message)
    console.error(e)
  }

  return data
}
