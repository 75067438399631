import * as React from 'react'
import type { SVGProps } from 'react'
const SvgCheckAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    viewBox="0 0 15 13"
    {...props}
  >
    <path
      fill="#4855DC"
      fillRule="evenodd"
      d="M12.944.622 4.59 8.684 2.374 6.315c-.409-.385-1.05-.408-1.517-.081-.455.338-.583.933-.303 1.411l2.625 4.27c.256.397.7.642 1.201.642.479 0 .934-.245 1.19-.642.42-.548 8.435-10.103 8.435-10.103C15.055.739 13.784-.206 12.944.61z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCheckAlt
