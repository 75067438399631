import React from 'react'

export const residentialSessionTimeIntervals = [
  {
    label: '1 min',
    value: '60',
  },
  {
    label: '5 min',
    value: '300',
  },
  {
    label: '10 min',
    value: '600',
  },
  {
    label: '60 min',
    value: '3600',
  },
]

export const mobileSessionTimeIntervals = [
  {
    label: '1 min',
    value: '60',
  },
  {
    label: '5 min',
    value: '300',
  },
  {
    label: '10 min',
    value: '600',
  },
  {
    label: '60 min',
    value: '3600',
  },
]

export const dcSessionTimeIntervals = [
  {
    label: '10 min',
    value: '600',
  },
  {
    label: '60 min',
    value: '3600',
  },
  {
    label: '12 h',
    value: '43200',
  },
  {
    label: '24 h',
    value: '86400',
  },
]

export const ispSessionTimeIntervals = [
  {
    label: '10 min',
    value: '600',
  },
  {
    label: '60 min',
    value: '3600',
  },
  {
    label: '12 h',
    value: '43200',
  },
  {
    label: '24 h',
    value: '86400',
  },
]

export const residentialSessionDefaultInterval = {
  label: '5 min',
  value: '300',
}

export const mobileSessionDefaultInterval = {
  label: '5 min',
  value: '300',
}

export const dcSessionDefaultInterval = {
  label: '10 min',
  value: '600',
}

export const ispSessionDefaultInterval = {
  label: '10 min',
  value: '600',
}

export const dcAvailableCountries = {
  de: {
    name: 'Germany',
    id: 57,
    code: 'de',
  },
  es: {
    name: 'Spain',
    id: 68,
    code: 'es',
  },
  fr: {
    name: 'France',
    id: 75,
    code: 'fr',
  },
  pl: {
    name: 'Poland',
    id: 179,
    code: 'pl',
  },
  gb: {
    name: 'United Kingdom',
    id: 77,
    code: 'gb',
  },
  us: {
    name: 'United States',
    id: 234,
    code: 'us',
  },
}

export const ispAvailableCountries = {
  us: {
    name: 'United States',
    id: 234,
    code: 'us',
  },
}

export const curlResultSample =
  'curl -k -x package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@proxy.soax.com:5000 -L https://checker.soax.com/api/ipinfo'

export const credResultSample1 =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}'

export const credResultSample =
  'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}'

export const urlResultSample1 =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2@proxy.soax.com:5000'

export const urlResultSample =
  'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@proxy.soax.com:5000'

export const proxyListResultSample1 =
  'package-246842-pool-{{proxy_type}}{{location}}-sessionid-{{session_prefix}}{{session_length}}:UJWOUJZBIPb0swh2@proxy.soax.com:5000'

export const proxyListResultSample =
  'package-{{package_id}}{{location}}{{session_prefix}}{{session_length}}:{{package_key}}@proxy.soax.com:5000'

export const getProxyPoolDescription = () => (
  <div>
    <p>
      <b>Residential Proxy:</b> Uses IP addresses from real homes, making it
      appear as legitimate user traffic, ideal for web scraping and accessing
      geo-restricted content.
    </p>
    <p>
      <b>Mobile Proxy:</b> Uses IPs from mobile carriers, frequently rotating,
      providing high anonymity for mobile app testing and managing multiple
      accounts.
    </p>
    <p>
      <b>Datacenter Proxy:</b> Uses IPs from data centers, offering high speed
      and cost-effectiveness, but easier to detect and block.
    </p>
    <p>
      <b>ISP Proxy:</b> Combines ISP-provided IPs with datacenter hosting,
      offering a balance of reliability, speed, and anonymity for tasks like
      streaming and market research.
    </p>
  </div>
)

export const ProxyPoolDescription = getProxyPoolDescription()
