import React from 'react'

import styles from './PlansComparisonTable.module.scss'

import { CaretDown } from '../../../icons'

import { getMinimalPrice, getNormalizedPrice } from 'utils/functions'

interface ISubColumnProps {
  title?: string
  items: { label: string; productId: string }[]
  productKey?: string
  state: boolean
  plan?: any
  handler: () => void
}

const SubColumn: React.FC<ISubColumnProps> = (props) => {
  const { title, items, state, plan, productKey, handler } = props

  const productPrices =
    productKey && plan.prices.monthly[productKey]
      ? Object.values(plan.prices.monthly[productKey])
      : null

  // console.log('productPrices', productPrices)

  // @ts-ignore
  const minProductPrice = getMinimalPrice(productPrices)

  const minProductPriceString = !!minProductPrice
    ? `from $${getNormalizedPrice(minProductPrice)}`
    : 'no product'

  const getItemPrice = (key: string) => {
    if (productKey && plan) {
      const products = plan.prices.monthly[productKey]

      if (!!products) {
        return `$${getNormalizedPrice(products[key])}` || 'n/a'
      } else {
        return 'no product'
      }
    }
  }

  const itemsList = items.map((item, index) => (
    <div className={styles.cell} key={`subcolumn-item_${item.productId}`}>
      {plan ? getItemPrice(item.productId) : item.label}
    </div>
  ))

  return (
    <div className={styles.subColumnWrapper}>
      <div
        className={`
              ${styles.cell}
              ${styles.toggle}
              ${state ? styles.active : ''}
            `}
        onClick={handler}
      >
        {!!title ? (
          <>
            {title}
            <div
              className={`
                ${styles.toggleIconWrapper}
                ${state ? styles.active : ''}
              `}
            >
              <CaretDown />
            </div>
          </>
        ) : (
          minProductPriceString
        )}
      </div>

      <div
        className={`
              ${styles.subColumn}
              ${styles.rowLabels}
              ${state ? styles.active : ''}
            `}
      >
        {itemsList}
      </div>
    </div>
  )
}

export default SubColumn
