import { ServiceActionsTypes } from './actions'
import { IServiceAction, IServiceState } from 'models/service'

const initialState: IServiceState = {
  fetching: false,
  locationsFetching: false,
  locations: null,
  locationTargetsFetching: false,
  locationTargets: null,
}

const serviceReducer = (
  state: IServiceState = initialState,
  action: IServiceAction
) => {
  switch (action.type) {
    case ServiceActionsTypes.SERVICE_GET_LOCATIONS:
      return {
        ...state,
        locationsFetching: true,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationsFetching: false,
        locations: action.locations,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATIONS_FAIL:
      return {
        ...state,
        locationsFetching: false,
      }

    case ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS:
      return {
        ...state,
        locationTargetsFetching: true,
        locationTargets: null,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS_SUCCESS:
      return {
        ...state,
        locationTargetsFetching: false,
        locationTargets: action.locationTargets,
      }
    case ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS_FAIL:
      return {
        ...state,
        locationTargetsFetching: false,
        locationTargets: null,
      }

    default:
      return state
  }
}

export default serviceReducer
