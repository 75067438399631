import * as React from 'react'
import type { SVGProps } from 'react'
const SvgMobilePhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.625 8.75-.005.203.005.214-.005.203.005.214-.005.204.005.212-.135 5.92a2.8 2.8 0 0 1-2.497 2.718c-1.99.215-3.997.215-5.986 0A2.8 2.8 0 0 1 4.51 15.92L4.375 10l.004-.214-.004-.202.005-.214-.005-.203.005-.213-.005-.204.136-5.081a2 2 0 0 1 .015-.21l.003-.03.003-.024A2.22 2.22 0 0 1 5.66 1.682q.063-.038.13-.072c.272-.143.575-.236.899-.266a36 36 0 0 1 6.62 0 2.4 2.4 0 0 1 1.027.337q.068.038.131.08c.552.36.93.955 1 1.639l.006.062q.012.102.014.207zM14.228 3.6l.131 5.723-.106 3.942a1.75 1.75 0 0 1-1.59 1.696 29 29 0 0 1-5.327 0 1.75 1.75 0 0 1-1.59-1.696L5.64 9.324 5.771 3.6l.002-.037a1.15 1.15 0 0 1 .519-.8 1 1 0 0 1 .343-.104 31.3 31.3 0 0 1 6.73 0q.184.021.342.104c.276.175.47.465.52.8zM10 18.085a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMobilePhone
