import React from 'react'
import Modal from 'react-modal'

import styles from './CustomModal.module.scss'

import { Cross as CrossIcon } from '../../../icons'

interface IProps {
  isModalOpen: boolean
  modalWidth?: string
  modalHeight?: string
  title?: string
  showCross?: boolean
  className?: string
  shouldCloseOnOverlayClick?: boolean
  onModalClose: () => void
  children: React.ReactNode
}

Modal.setAppElement('#root')

const CustomModal: React.FC<IProps> = (props) => {
  const {
    isModalOpen,
    showCross,
    modalWidth,
    modalHeight,
    title,
    className,
    onModalClose,
    children,
    shouldCloseOnOverlayClick,
  } = props

  const modalStyles = {
    overlay: {
      backgroundColor: 'var(--popup-bg)',
      zIndex: 11000,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      padding: 0,
      borderRadius: '8px',
      maxHeight: 'calc(100% - 24px)',
      width: modalWidth || '520px',
      height: modalHeight || 'unset',
      maxWidth: '100%',
      display: 'flex',
    },
  }

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      ariaHideApp={false}
      style={modalStyles}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          {!!title && <div className={styles.title}>{title}</div>}

          {showCross && (
            <button onClick={onModalClose} className={styles.closeBtn}>
              <CrossIcon className={styles.closeIcon} />
            </button>
          )}
        </div>

        <div className={className || ''}>{children}</div>
      </div>
    </Modal>
  )
}

export default CustomModal
