import React, { useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { IntercomProvider } from 'react-use-intercom'
import { useDispatch } from 'react-redux'
import { LoggedIn, LoggedOut, useSlashID } from '@slashid/react'
import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'

import { resetToken, setupToken } from 'utils/request'

import '@slashid/react/style.css'
import 'react-toastify/dist/ReactToastify.css'

import configureStore from 'store'

import './App.scss'

import AuthRoutes from './AuthRoutes'
import AppRoutes from './AppRoutes'
import { UserActionsTypes } from 'store/user/actions'
import { resetAmplitude } from '../utils/analytics'

const store = configureStore()
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ''

const App = () => {
  return (
    <Provider store={store}>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        /*autoBootProps={{
          userHash: intercomHash,
          email: userEmail,
          userId: userHash,
        }}*/
        autoBoot
        // onShow={handleMessengerShowing}
      >
        <AppContent />
      </IntercomProvider>
    </Provider>
  )
}

const AppContent = () => {
  const { user, logOut } = useSlashID()
  const dispatch = useDispatch()

  useEffect(() => {
    Cookies.set('customer_timezone', moment.tz.guess())
  }, [])

  useEffect(() => {
    if (!!user && user.token) {
      setupToken(user.token)
    }
  }, [user])

  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  )

  const shouldLogout = queryParams.get('logout') === 'true'
  const forceNewDash = queryParams.get('new_dash') === 'true'

  useEffect(() => {
    if (shouldLogout) {
      logOut()

      resetAmplitude()
      resetToken()

      window.location.reload()
    }
  }, [logOut, queryParams, shouldLogout])

  useEffect(() => {
    if (!!user && user.token && !shouldLogout) {
      dispatch({
        type: UserActionsTypes.USER_INITIALIZE,
        isFirstLogin: user.firstLogin,
        forceNewDash: forceNewDash,
      })
    }
  }, [user, dispatch, shouldLogout, forceNewDash])

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Get access to proxies, web unblocker, and more."
        />
      </Helmet>

      <LoggedOut>
        <AuthRoutes />
      </LoggedOut>

      <LoggedIn>
        <AppRoutes />

        <ToastContainer
          // closeButton={<CloseToastButton/>}
          hideProgressBar={true}
          draggable={false}
          // className="toast-custom-container"
        />
      </LoggedIn>
    </>
  )
}

export default App
