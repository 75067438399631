import React from 'react'
import { useSlashID } from '@slashid/react'
import { useIntercom } from 'react-use-intercom'

import styles from './UserInitFailedPage.module.scss'

import { InfoTriangle } from '../../components/icons'

import CustomButton from 'components/elements/buttons/CustomButton'
import InfoNotice from 'components/elements/blocks/InfoNotice'
import { resetAmplitude } from 'utils/analytics'
import { resetToken } from 'utils/request'
import MainHeader from '../../components/navigation/MainHeader'

const UserInitFailedPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'Error',
      isActive: true,
    },
  ]

  const { logOut } = useSlashID()
  const { show } = useIntercom()

  const handleLogout = () => {
    logOut()

    resetAmplitude()
    resetToken()

    window.location.reload()
  }

  const handleSupport = () => {
    show()
  }

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} reducedDisplayMode />

      <InfoNotice
        icon={<InfoTriangle />}
        color={'blue'}
        title={'Authorization Error'}
        className={styles.notice}
      >
        Something went wrong. Please log out, clear your cookies, and log in. If
        the issue persists, contact{' '}
        <span className={styles.link} onClick={handleSupport}>
          support chat
        </span>
        .
      </InfoNotice>

      <CustomButton onClick={handleLogout}>Sign out</CustomButton>
    </>
  )
}

export default UserInitFailedPage
