import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../ProxyContent/ProxyContent.module.scss'

import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import ProxyListConfiguration from './ProxyListConfiguration'
import ProxyListResult from './ProxyListResult'
import {
  Apps as DCIcon,
  MobilePhone as MobileIcon,
  Server as ISPIcon,
  Wifi as WifiIcon,
} from '../../../icons'
import Flag from 'components/elements/other/Flag'

import { selectProxyPackages } from 'store/user/selectors'
import {
  randomCity,
  randomCountry,
  randomIsp,
  randomRegion,
} from 'utils/constants'
import { IProxyPackage } from 'models/user'
import { getDeepTargetResult } from 'utils/functions'
import { ServiceActionsTypes } from 'store/service/actions'
import {
  getAvailableSessionIntervals,
  getProxyResultString,
} from 'utils/proxy/proxyFunctions'
import {
  dcSessionDefaultInterval,
  ispSessionDefaultInterval,
  mobileSessionDefaultInterval,
  residentialSessionDefaultInterval,
  proxyListResultSample,
} from 'utils/proxy/proxyConstants'
import { PricingActionsTypes } from 'store/pricing/actions'

const proxyTypesOptions = [
  {
    label: 'Residential proxy',
    shortLabel: 'Residential',
    value: 'wifi',
    icon: <WifiIcon />,
  },
  {
    label: 'Mobile proxy',
    shortLabel: 'Mobile',
    value: 'mobile',
    icon: <MobileIcon />,
  },
  {
    label: 'Datacenter proxy',
    shortLabel: 'DC',
    value: 'dc',
    icon: <DCIcon />,
  },
  {
    label: 'ISP proxy',
    shortLabel: 'ISP',
    value: 'isp',
    icon: <ISPIcon />,
  },
]

const ProxyListGenerator: React.FC = () => {
  const dispatch = useDispatch()
  const proxyPackages = useSelector(selectProxyPackages)

  const [selectedProxyType, setProxyType] = useState<ISelectOption>(
    proxyTypesOptions[0]
  )
  const [selectedCountry, setCountry] = useState<ISelectOption>(randomCountry)
  const [selectedRegion, setRegion] = useState<ISelectOption>(randomRegion)
  const [selectedCity, setCity] = useState<ISelectOption>(randomCity)
  const [selectedIsp, setIsp] = useState<ISelectOption>(randomIsp)
  const [selectedTimeInterval, setTimeInterval] = useState<ISelectOption>({
    label: '5 min',
    value: '300',
  })
  const [isBindIPActive, toggleBindIPActive] = useState(false)
  const [activeSessionLengthType, setSessionLengthType] = useState<
    'same' | 'new'
  >('same')
  const [sessionPrefix, setSessionPrefix] = useState('number01')
  const [connectionsAmount, setConnectionsAmount] = useState(10)
  const [listResult, setListResult] = useState<string[] | null>(null)
  const [wasInitiallyGenerated, toggleInitiallyGenerated] = useState(false)

  const getSelectedProxyPackage = () => {
    const selectedTypePackagesArray = proxyPackages.filter(
      (proxyPackage: IProxyPackage) => {
        return proxyPackage.connection_type === selectedProxyType.value
      }
    )

    return selectedTypePackagesArray[0]
  }

  const selectedProxyPackage = getSelectedProxyPackage()

  const availableSessionIntervals = getAvailableSessionIntervals(
    selectedProxyType.value
  )

  const deepTargetResult = getDeepTargetResult(
    selectedRegion.value,
    selectedCity.value,
    selectedIsp.value
  )

  const getListResult = () => {
    let listResult: string[] = []

    for (let i = 1; i <= connectionsAmount; i++) {
      listResult.push(
        getProxyResultString(
          proxyListResultSample,
          selectedProxyPackage.id,
          selectedProxyPackage.package_key,
          selectedCountry.value,
          deepTargetResult,
          activeSessionLengthType,
          selectedTimeInterval.value,
          isBindIPActive,
          `${sessionPrefix}_x${i}`
        )
      )
    }

    return listResult
  }

  const handleProxyTypeChange = (option: any) => {
    setProxyType(option)

    handleCountryChange({
      label: 'United States',
      value: 'us',
      icon: <Flag countryCode={'us'} className={styles.optionFlag} />,
    })

    handleRegionChange(randomRegion)
    handleCityChange(randomCity)
    handleIspChange(randomIsp)

    dispatch({
      type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE,
      productIdOrb: selectedProxyType.value,
    })
  }

  useEffect(() => {
    handleCountryChange({
      label: 'United States',
      value: 'us',
      icon: <Flag countryCode={'us'} className={styles.optionFlag} />,
    })

    dispatch({
      type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE,
      productIdOrb: proxyTypesOptions[0].value,
    })
  }, [])

  const handleCountryChange = (option: any) => {
    setCountry(option)

    handleRegionChange(randomRegion, false)
    handleCityChange(randomCity, false)
    handleIspChange(randomIsp)

    dispatch({
      type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
      data: { country: option.value },
    })
  }

  const handleRegionChange = (option: any, shouldRequest: boolean = true) => {
    setRegion(option)

    handleCityChange(randomCity, false)
    handleIspChange(randomIsp)

    if (shouldRequest) {
      dispatch({
        type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
        data: {
          country: selectedCountry.value,
          region: option.value !== 'any' ? option.value : undefined,
        },
      })
    }
  }

  const handleCityChange = (option: any, shouldRequest: boolean = true) => {
    setCity(option)

    handleIspChange(randomIsp)

    if (shouldRequest) {
      dispatch({
        type: ServiceActionsTypes.SERVICE_GET_LOCATION_TARGETS,
        data: {
          country: selectedCountry.value,
          region: selectedRegion.value,
          city: option.value !== 'any' ? option.value : undefined,
        },
      })
    }
  }

  const handleIspChange = (option: any) => {
    setIsp(option)
  }

  const handleTimeIntervalChange = (option: any) => {
    setTimeInterval(option)
  }

  const handleBindIP = () => {
    toggleBindIPActive(!isBindIPActive)
  }

  const handleActiveSessionLengthChange = (value: 'new' | 'same') => {
    setSessionLengthType(value)
  }

  const handleSessionPrefixChange = (e: any) => {
    setSessionPrefix(e.target.value)
  }

  const handleConnectionsAmountChange = (e: any) => {
    setConnectionsAmount(e.target.value)
  }

  const handleGenerate = () => {
    const result = getListResult()

    setListResult(result)
  }

  useEffect(() => {
    if (selectedCountry.value !== 'any' && !wasInitiallyGenerated) {
      handleGenerate()
      toggleInitiallyGenerated(true)
    }
  }, [wasInitiallyGenerated, selectedCountry.value, handleGenerate])

  useEffect(() => {
    if (selectedProxyType.value === 'wifi') {
      handleTimeIntervalChange(residentialSessionDefaultInterval)
    } else if (selectedProxyType.value === 'mobile') {
      handleTimeIntervalChange(mobileSessionDefaultInterval)
    } else if (selectedProxyType.value === 'dc') {
      handleTimeIntervalChange(dcSessionDefaultInterval)
    } else if (selectedProxyType.value === 'isp') {
      handleTimeIntervalChange(ispSessionDefaultInterval)
    }
  }, [selectedProxyType.value])

  return (
    <div className={styles.container}>
      <div
        className={`
          ${styles.contentBlock}
          ${styles.leftSide}
        `}
      >
        <ProxyListConfiguration
          selectedProxyType={selectedProxyType}
          selectedTimeInterval={selectedTimeInterval}
          selectedCountry={selectedCountry}
          selectedRegion={selectedRegion}
          selectedCity={selectedCity}
          selectedIsp={selectedIsp}
          isBindIPActive={isBindIPActive}
          activeSessionLengthType={activeSessionLengthType}
          sessionPrefix={sessionPrefix}
          proxyTypesOptions={proxyTypesOptions}
          availableSessionIntervals={availableSessionIntervals}
          connectionsAmount={connectionsAmount}
          onProxyTypeChange={handleProxyTypeChange}
          onTimeIntervalChange={handleTimeIntervalChange}
          onBindIp={handleBindIP}
          onActiveSessionLengthChange={handleActiveSessionLengthChange}
          onSessionPrefixChange={handleSessionPrefixChange}
          onCountryChange={handleCountryChange}
          onRegionChange={handleRegionChange}
          onCityChange={handleCityChange}
          onIspChange={handleIspChange}
          onConnectionsAmountChange={handleConnectionsAmountChange}
          onGenerate={handleGenerate}
        />
      </div>

      <div
        className={`
          ${styles.contentBlock}
          ${styles.rightSide}
        `}
      >
        <ProxyListResult
          selectedProxyType={selectedProxyType}
          resultArray={listResult}
        />
      </div>
    </div>
  )
}

export default ProxyListGenerator
