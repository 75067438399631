import React from 'react'
import { useSelector } from 'react-redux'

import styles from './AccountSettings.module.scss'

// import CustomButton from 'components/elements/buttons/CustomButton'
import AccountSettingsForm from './AccountSettingsForm'
// import Accordion from 'components/elements/blocks/Accordion'

import {
  selectUserFirstName,
  selectUserLastName,
  selectUserEmail,
} from 'store/user/selectors'

const AccountSettings: React.FC = () => {
  const firstName = useSelector(selectUserFirstName)
  const lastName = useSelector(selectUserLastName)
  const email = useSelector(selectUserEmail)

  const info = {
    email: email || '',
    first_name: firstName || '',
    last_name: lastName || '',
  }

  const handleFormSubmit = (info: any) => {
    console.log(info)
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Account information</h3>

      <div className={styles.formWrapper}>
        <AccountSettingsForm info={info} onFormSubmit={handleFormSubmit} />
      </div>

      {/*<Accordion title={'Danger zone'}>
        <>
          <p className={styles.accordionText}>
            If you have any issues, please contact out support team, we really
            want to help you
          </p>
          <CustomButton color={'red'}>I want to delete my account</CustomButton>
        </>
      </Accordion>*/}
    </div>
  )
}

export default AccountSettings
