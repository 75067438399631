import React from 'react'
import { useSelector } from 'react-redux'

import styles from './ScrapingDocs.module.scss'

import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import CopyButton from 'components/elements/buttons/CopyButton'
import { selectScrapperApiKey } from 'store/user/selectors'

const ScrapingDocs: React.FC = () => {
  const scrapperApiKey = useSelector(selectScrapperApiKey)

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>API documentation</h3>

      <div className={styles.content}>
        <div className={styles.docs}>
          Whole API documentation is here:{' '}
          <a
            href="https://docs.soax.com/#intro"
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            https://docs.soax.com
          </a>
        </div>

        <InputGroup className={styles.inputGroup} labelTop={'Your API key'}>
          <CustomInput
            className={styles.keyInput}
            placeholder={'Key'}
            value={scrapperApiKey}
            readOnly
            // onChange={}
          />

          <CopyButton
            className={styles.copy}
            text={scrapperApiKey}
            color={'outline'}
          />

          {/*<CustomButton color={'outline'}>Generate new</CustomButton>*/}
        </InputGroup>

        {/*<div className={styles.docs}>
          <ul className={styles.docsList}>
            <li className={styles.docsListItem}>
              <div className={styles.checkIconWrapper}>
                <CheckIcon />
              </div>
              <div>
                <div>
                  eCommerce API{' '}
                  <a
                    href="soax.com/scraping-api/docs"
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Postman link
                  </a>
                </div>
                <div>Amazon, Walmart</div>
              </div>
            </li>

            <li className={styles.docsListItem}>
              <div className={styles.checkIconWrapper}>
                <CheckIcon />
              </div>
              <div>
                <div>
                  SERP API{' '}
                  <a
                    href="soax.com/scraping-api/docs"
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Postman link
                  </a>
                </div>
              </div>
            </li>

            <li className={styles.docsListItem}>
              <div className={styles.checkIconWrapper}>
                <CheckIcon />
              </div>
              <div>
                <div>
                  Public social API{' '}
                  <a
                    href="soax.com/scraping-api/docs"
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Postman link
                  </a>
                </div>
                <div>Facebook, Instagram, LinkedIn</div>
              </div>
            </li>
          </ul>
        </div>*/}
      </div>
    </div>
  )
}

export default ScrapingDocs
