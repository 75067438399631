import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './ScrapingBuilder.module.scss'

import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomInput from 'components/elements/inputs/CustomInput'
import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import TabsList from 'components/elements/tabs/TabsList'
import CopyButton from 'components/elements/buttons/CopyButton'

import { PricingActionsTypes } from 'store/pricing/actions'
import {
  selectProductPrice,
  selectProductPriceFetching,
} from 'store/pricing/selectors'
import { selectScrapperApiKey } from 'store/user/selectors'

import {
  JSONData,
  getApiTypes,
  getSelectOptions,
  isLastLevel,
} from 'json-parser/json-parser'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'
import { getNormalizedPrice } from 'utils/functions'
import { Check as CheckIcon } from '../../../icons'

interface IScrapperParam {
  default_value: string
  description: string
  display_name: string
  key: string
  required: boolean
  value: string
}

const ScrapingBuilder: React.FC = () => {
  const dispatch = useDispatch()

  const productPrice = useSelector(selectProductPrice)
  const productPriceFetching = useSelector(selectProductPriceFetching)
  const scrapperApiKey = useSelector(selectScrapperApiKey)

  const [selectedApiType, setApiType] = useState<ISelectOption | null>({
    label: 'SERP',
    value: 'SERP',
  })

  const [selectedFirstLevelOption, setFirstLevelOption] =
    useState<ISelectOption | null>({
      label: 'Google Search API',
      value: 'Google Search API',
    })
  const [selectedSecondLevelOption, setSecondLevelOption] =
    useState<ISelectOption | null>({
      label: 'Detailed',
      value: 'Detailed',
    })
  const [selectedThirdLevelOption, setThirdLevelOption] =
    useState<ISelectOption | null>({
      label: 'Google Search API',
      value: 'Google Search API',
    })
  const [selectedFourthLevelOption, setFourthLevelOption] =
    useState<ISelectOption | null>(null)
  const [selectedFifthLevelOption, setFifthLevelOption] =
    useState<ISelectOption | null>(null)
  const [allowedParams, setParams] = useState<object>({})

  const [selectedCommandTypeIndex, setCommandTypeIndex] = useState(0)

  const apiTypes = getApiTypes()

  const commandTypes = [
    {
      label: 'cURL',
      value: 'Sample Curl Code',
    },
    {
      label: 'Python',
      value: 'Sample Python Code',
    },
    /*{
      label: 'Node.JS',
      value: 'node',
    },
    {
      label: 'PHP',
      value: 'php',
    },
    {
      label: 'Ruby',
      value: 'ruby',
    },*/
  ]

  const selectedOptions = useMemo(() => {
    return [
      selectedApiType,
      selectedFirstLevelOption,
      selectedSecondLevelOption,
      selectedThirdLevelOption,
      selectedFourthLevelOption,
      selectedFifthLevelOption,
    ]
  }, [
    selectedApiType,
    selectedFirstLevelOption,
    selectedSecondLevelOption,
    selectedThirdLevelOption,
    selectedFourthLevelOption,
    selectedFifthLevelOption,
  ])

  const handleApiTypeChange = (option: any) => {
    setApiType(option)

    setFirstLevelOption(null)
    setSecondLevelOption(null)
    setThirdLevelOption(null)
    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleFirstSelectChange = (option: any) => {
    setFirstLevelOption(option)

    setSecondLevelOption(null)
    setThirdLevelOption(null)
    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleSecondSelectChange = (option: any) => {
    setSecondLevelOption(option)

    setThirdLevelOption(null)
    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleThirdSelectChange = (option: any) => {
    setThirdLevelOption(option)

    setFourthLevelOption(null)
    setFifthLevelOption(null)
    setParams({})
  }

  const handleFourthSelectChange = (option: any) => {
    setFourthLevelOption(option)

    setFifthLevelOption(null)
    setParams({})
  }

  const handleFifthSelectChange = (option: any) => {
    setFifthLevelOption(option)
    setParams({})
  }

  /*const selectsHandlers = useMemo(() => {
    return [
      handleApiTypeChange,
      handleFirstSelectChange,
      handleSecondSelectChange,
      handleThirdSelectChange,
      handleFourthSelectChange,
      handleFifthSelectChange,
    ]
  }, [
    handleApiTypeChange,
    handleFirstSelectChange,
    handleSecondSelectChange,
    handleThirdSelectChange,
    handleFourthSelectChange,
    handleFifthSelectChange,
  ])

  const selectsNames = useMemo(() => {
    return [
      'api-type-select',
      'first-level-select',
      'second-level-select',
      'third-level-select',
      'fourth-level-select',
      'fifth-level-select',
    ]
  }, [])*/

  const getLastLevelData = useCallback(() => {
    let data = JSONData

    for (let i = selectedOptions.length - 1; i >= 0; i--) {
      if (selectedOptions[i]) {
        for (let k = 0; k <= i; k++) {
          // @ts-ignore
          data = data[selectedOptions[k].value]
        }

        break
      }
    }

    return data
  }, [selectedOptions])

  const lastLevelData = useMemo(() => getLastLevelData(), [getLastLevelData])

  const handleCodeTypeChange = (index: number) => {
    setCommandTypeIndex(index)
  }

  const getProductName = () => {
    let names: string[] = []

    selectedOptions.forEach((option) => {
      if (option?.label) {
        names.push(option.label)
      }
    })

    return `Scraping APIs: ${names.join(' > ')}`
  }

  const productName = getProductName()

  const normalizedPrice = getNormalizedPrice(productPrice)

  const productPriceString =
    // @ts-ignore
    productPrice && lastLevelData?.product_id_orb
      ? `$${normalizedPrice} per 1000 requests`
      : ''

  useEffect(() => {
    if (
      // @ts-ignore
      lastLevelData?.Params?.length &&
      !!allowedParams &&
      !Object.keys(allowedParams).length
    ) {
      let levelParams = {}

      // @ts-ignore
      lastLevelData.Params.forEach((param: IScrapperParam) => {
        levelParams = {
          ...levelParams,
          [param.value || `{{${param.key}}}`]: {
            value: param.default_value,
            required: param.required,
            key: param.key,
          },
        }
      })

      setParams(levelParams)
    }
  }, [lastLevelData, allowedParams])

  const handleParamChange = useCallback(
    (e: any) => {
      const param = {
        value: e.target.value,
        // @ts-ignore
        required: allowedParams[e.target.name].required,
        // @ts-ignore
        key: allowedParams[e.target.name].key,
      }

      setParams({
        ...allowedParams,
        [e.target.name]: param,
      })
    },
    [allowedParams]
  )

  const getCurrentCodeExample = () => {
    // @ts-ignore
    if (lastLevelData.examples) {
      const activeCommandTypeValue =
        commandTypes[selectedCommandTypeIndex].value

      // @ts-ignore
      return lastLevelData.examples[activeCommandTypeValue]
    } else {
      return null
    }
  }

  // @ts-ignore
  const shouldParamsContainSign = lastLevelData?.URL?.indexOf('?') < 0

  const currentCodeExample = getCurrentCodeExample()

  const getCodeString = () => {
    console.log('allowedParams', allowedParams)
    if (currentCodeExample && !!allowedParams) {
      if (!!Object.keys(allowedParams).length) {
        let codeString = currentCodeExample
        let paramsArray: string[] = []

        Object.keys(allowedParams).forEach((objectKey) => {
          // @ts-ignore
          if (allowedParams[objectKey].required) {
            codeString = codeString.replace(
              objectKey,
              // @ts-ignore
              allowedParams[objectKey]?.value.split(' ').join('+')
            )
          } else if (
            // @ts-ignore
            !allowedParams[objectKey].required &&
            // @ts-ignore
            !!allowedParams[objectKey]?.value
          ) {
            paramsArray.push(
              // @ts-ignore
              `${allowedParams[objectKey]?.key}=${allowedParams[objectKey]?.value}`
            )
          }
        })

        const firstParamsSign =
          shouldParamsContainSign && !!paramsArray.length
            ? '?'
            : !!paramsArray.length
              ? '&'
              : ''

        const paramsString = `${firstParamsSign}${paramsArray.join('&')}`

        codeString = codeString
          .replace('{{placeholder_params}}', paramsString)
          .replace('{{X-SOAX-API-Secret}}', scrapperApiKey)

        return codeString
      } else {
        return currentCodeExample
          .replace('{{placeholder_params}}', '')
          .replace('{{X-SOAX-API-Secret}}', scrapperApiKey)
      }
    } else {
      return 'Please select options'
    }
  }

  const codeString = getCodeString()

  const isRequestFree = selectedApiType?.value === 'Get Stats'

  const renderParamsInputs = useCallback(() => {
    // @ts-ignore
    if (!!lastLevelData.Params && !!lastLevelData.Params.length) {
      // @ts-ignore
      return lastLevelData.Params.map(
        (param: IScrapperParam, index: number) => {
          const paramName = param.value || `{{${param.key}}}`

          return (
            <InputGroup
              key={`param-input__${index}`}
              className={styles.inputGroup}
              labelTop={
                param.required ? `${param.display_name}*` : param.display_name
              }
              description={param.description}
              name={paramName}
            >
              <CustomInput
                className={styles.input}
                // @ts-ignore
                value={allowedParams[paramName]?.value}
                name={paramName}
                onChange={handleParamChange}
                error={
                  // @ts-ignore
                  !allowedParams[paramName]?.value &&
                  // @ts-ignore
                  allowedParams[paramName]?.required
                }
              />
            </InputGroup>
          )
        }
      )
    } else {
      return null
    }
    // @ts-ignore
  }, [lastLevelData.Params, allowedParams, handleParamChange])

  useEffect(() => {
    // @ts-ignore
    if (!!lastLevelData?.product_id_orb && !isRequestFree) {
      dispatch({
        type: PricingActionsTypes.PRICING_GET_PRODUCT_PRICE,
        // @ts-ignore
        productIdOrb: lastLevelData.product_id_orb,
      })
    }
    // @ts-ignore
  }, [dispatch, isRequestFree, lastLevelData.product_id_orb])

  const renderSelect = (
    levelData: any,
    previousLevelOption: ISelectOption | null,
    selectValue: ISelectOption | null,
    changeHandler: (option: any) => void,
    selectName: string
  ) => {
    // @ts-ignore
    if (previousLevelOption && !isLastLevel(levelData)) {
      return (
        <InputGroup className={styles.inputGroup}>
          <CustomSelect
            onChange={changeHandler}
            value={selectValue}
            name={selectName}
            // @ts-ignore
            options={getSelectOptions(levelData)}
            // error={'Keke'}
          />
        </InputGroup>
      )
    } else {
      return null
    }
  }

  const renderFirstLevelSelect = () => {
    if (!selectedApiType) return null
    // @ts-ignore
    const levelData = JSONData[selectedApiType.value]

    return renderSelect(
      levelData,
      selectedApiType,
      selectedFirstLevelOption,
      handleFirstSelectChange,
      'first-level-select'
    )
  }

  const renderSecondLevelSelect = () => {
    if (!selectedApiType || !selectedFirstLevelOption) return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value]

    return renderSelect(
      levelData,
      selectedFirstLevelOption,
      selectedSecondLevelOption,
      handleSecondSelectChange,
      'second-level-select'
    )
  }

  const renderThirdLevelSelect = () => {
    if (
      !selectedApiType ||
      !selectedFirstLevelOption ||
      !selectedSecondLevelOption
    )
      return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value][
        selectedSecondLevelOption.value
      ]

    return renderSelect(
      levelData,
      selectedSecondLevelOption,
      selectedThirdLevelOption,
      handleThirdSelectChange,
      'third-level-select'
    )
  }

  const renderFourthLevelSelect = () => {
    if (
      !selectedApiType ||
      !selectedFirstLevelOption ||
      !selectedSecondLevelOption ||
      !selectedThirdLevelOption
    )
      return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value][
        selectedSecondLevelOption.value
      ][selectedThirdLevelOption.value]

    return renderSelect(
      levelData,
      selectedThirdLevelOption,
      selectedFourthLevelOption,
      handleFourthSelectChange,
      'fourth-level-select'
    )
  }

  const renderFifthLevelSelect = () => {
    if (
      !selectedApiType ||
      !selectedFirstLevelOption ||
      !selectedSecondLevelOption ||
      !selectedThirdLevelOption ||
      !selectedFourthLevelOption
    )
      return null

    const levelData =
      // @ts-ignore
      JSONData[selectedApiType.value][selectedFirstLevelOption.value][
        selectedSecondLevelOption.value
      ][selectedThirdLevelOption.value][selectedFourthLevelOption.value]

    return renderSelect(
      levelData,
      selectedFourthLevelOption,
      selectedFifthLevelOption,
      handleFifthSelectChange,
      'fifth-level-select'
    )
  }

  return (
    <div className={styles.container}>
      <div
        className={`
          ${styles.contentBlock}
          ${styles.leftSide}
        `}
      >
        <h3 className={styles.title}>Make your request</h3>

        <div className={styles.form}>
          <InputGroup className={styles.inputGroup} labelTop={'API type'}>
            <CustomSelect
              onChange={handleApiTypeChange}
              value={selectedApiType}
              name={'api-type-select'}
              options={apiTypes}
              // error={'Keke'}
            />
          </InputGroup>

          {renderFirstLevelSelect()}
          {renderSecondLevelSelect()}
          {renderThirdLevelSelect()}
          {renderFourthLevelSelect()}
          {renderFifthLevelSelect()}

          <div className={styles.formRow}>{renderParamsInputs()}</div>
        </div>
      </div>
      <div
        className={`
          ${styles.contentBlock}
          ${styles.rightSide}
        `}
      >
        <div className={styles.backgroundBlock} />

        <div className={styles.blockHeader}>
          <span className={styles.blockTitle}>Result</span>

          <div>
            <div className={styles.checkIconWrapper}>
              <CheckIcon />
            </div>
            Ready
          </div>
        </div>

        {!isRequestFree && (
          <ProductPricingBlock
            className={styles.pricingBlock}
            product={productName}
            price={productPriceString}
            loading={productPriceFetching}
          />
        )}

        <div className={styles.resultContent}>
          <div className={styles.resultTabsWrapper}>
            <TabsList
              items={commandTypes}
              onChange={handleCodeTypeChange}
              activeTabIndex={selectedCommandTypeIndex}
            />

            <CopyButton text={codeString} size={'s'} />
          </div>
        </div>

        <div className={styles.builderContent}>
          <span>$</span> {codeString}
        </div>
      </div>
    </div>
  )
}

export default ScrapingBuilder
