import { PricingActionsTypes } from 'store/pricing/actions'
import { IPricingAction, IPricingState } from 'models/pricing'

const initialState: IPricingState = {
  plansFetching: false,
  plans: null,
  productPrice: null,
  productPriceFetching: false,
  subscriptionProcessing: false,
  invoiceUrl: null,
  isBillingFormShown: false,
  couponValidationProcessing: false,
  appliedCouponInfo: null,
  isCouponInvalid: false,
  isTopUpModalShown: false,
  topUpBalanceProcessing: false,
  cancelSubscriptionProcessing: false,
  isInvoiceModalShown: false,
}

const pricingReducer = (
  state: IPricingState = initialState,
  action: IPricingAction
) => {
  switch (action.type) {
    case PricingActionsTypes.PRICING_GET_PLANS:
      return {
        ...state,
        plansFetching: true,
      }
    case PricingActionsTypes.PRICING_GET_PLANS_SUCCESS:
      return {
        ...state,
        plansFetching: false,
        plans: action.plans,
      }
    case PricingActionsTypes.PRICING_GET_PLANS_FAIL:
      return {
        ...state,
        plansFetching: false,
      }

    case PricingActionsTypes.PRICING_GET_PRODUCT_PRICE:
      return {
        ...state,
        productPriceFetching: true,
        productPrice: null,
      }
    case PricingActionsTypes.PRICING_GET_PRODUCT_PRICE_SUCCESS:
      return {
        ...state,
        productPriceFetching: false,
        productPrice: action.productPrice,
      }
    case PricingActionsTypes.PRICING_GET_PRODUCT_PRICE_FAIL:
      return {
        ...state,
        productPriceFetching: false,
      }

    case PricingActionsTypes.PRICING_GET_INVOICE:
      return {
        ...state,
        subscriptionProcessing: true,
        invoiceUrl: null,
      }
    case PricingActionsTypes.PRICING_GET_INVOICE_SUCCESS:
      return {
        ...state,
        subscriptionProcessing: false,
        invoiceUrl: action.invoiceUrl,
      }
    case PricingActionsTypes.PRICING_GET_INVOICE_FAIL:
      return {
        ...state,
        invoiceUrl: null,
        subscriptionProcessing: false,
      }

    case PricingActionsTypes.PRICING_RESET_INVOICE:
      return {
        ...state,
        invoiceUrl: null,
      }

    case PricingActionsTypes.PRICING_SHOW_INVOICE_MODAL:
      return {
        ...state,
        isInvoiceModalShown: true,
      }
    case PricingActionsTypes.PRICING_HIDE_INVOICE_MODAL:
      return {
        ...state,
        isInvoiceModalShown: false,
      }

    case PricingActionsTypes.PRICING_SHOW_BILLING_FORM:
      return {
        ...state,
        isBillingFormShown: true,
      }
    case PricingActionsTypes.PRICING_HIDE_BILLING_FORM:
      return {
        ...state,
        isBillingFormShown: false,
        subscriptionProcessing: false,
      }

    case PricingActionsTypes.PRICING_TOGGLE_TOP_UP_MODAL:
      return {
        ...state,
        isTopUpModalShown: action.isTopUpModalShown,
      }

    case PricingActionsTypes.PRICING_VALIDATE_COUPON:
      return {
        ...state,
        couponValidationProcessing: true,
        appliedCouponInfo: null,
      }
    case PricingActionsTypes.PRICING_VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        couponValidationProcessing: false,
        appliedCouponInfo: action.appliedCouponInfo,
      }
    case PricingActionsTypes.PRICING_VALIDATE_COUPON_FAIL:
      return {
        ...state,
        couponValidationProcessing: false,
        appliedCouponInfo: null,
      }

    case PricingActionsTypes.PRICING_SET_COUPON_VALIDATION_ERROR:
      return {
        ...state,
        isCouponInvalid: action.isCouponInvalid,
      }

    case PricingActionsTypes.PRICING_TOP_UP_BALANCE:
      return {
        ...state,
        topUpBalanceProcessing: true,
      }
    case PricingActionsTypes.PRICING_TOP_UP_BALANCE_SUCCESS:
      return {
        ...state,
        topUpBalanceProcessing: false,
      }
    case PricingActionsTypes.PRICING_TOP_UP_BALANCE_FAIL:
      return {
        ...state,
        topUpBalanceProcessing: false,
      }

    case PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscriptionProcessing: true,
      }
    case PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionProcessing: false,
      }
    case PricingActionsTypes.PRICING_CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        cancelSubscriptionProcessing: false,
      }

    case PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP:
      return {
        ...state,
        topUpBalanceProcessing: true,
      }
    case PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP_SUCCESS:
      return {
        ...state,
        topUpBalanceProcessing: false,
      }
    case PricingActionsTypes.PRICING_CANCEL_AUTO_TOP_UP_FAIL:
      return {
        ...state,
        topUpBalanceProcessing: false,
      }

    default:
      return state
  }
}

export default pricingReducer
