import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import {
  selectUserOverviewUrl,
  selectOverviewFetching,
} from 'store/user/selectors'
import { UserActionsTypes } from 'store/user/actions'

import styles from './StatisticsPage.module.scss'

import Loader from 'components/elements/progress/Loader'

const StatisticsPage: React.FC = () => {
  const dispatch = useDispatch()

  const orbStatisticsLink = useSelector(selectUserOverviewUrl)
  const statisticFetching = useSelector(selectOverviewFetching)

  useEffect(() => {
    dispatch({ type: UserActionsTypes.USER_GET_OVERVIEW })
  }, [dispatch])

  return (
    <section className={styles.container}>
      {statisticFetching ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <iframe
          src={orbStatisticsLink}
          frameBorder="0"
          title={'Orb statistics'}
          allow="camera;microphone"
        />
      )}
    </section>
  )
}

export default StatisticsPage
