import React from 'react'

import styles from './ProductPricingBlock.module.scss'

import { Card as CardIcon } from '../../../icons'
import Loader from 'components/elements/progress/Loader'

interface IProductPricingProps {
  product: string
  price: string
  loading?: boolean
  className?: string
}

const ProductPricingBlock: React.FC<IProductPricingProps> = (props) => {
  const { price, product, loading, className } = props

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <CardIcon />
        </div>

        <div className={styles.title}>Selected product pricing</div>
      </div>

      <div className={styles.product}>{product}</div>

      <div className={styles.separator} />

      <div className={styles.price}>{price}</div>

      {loading && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default ProductPricingBlock
