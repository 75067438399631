import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'

import styles from './PlansComparisonTable.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import { ICustomButtonProps  } from 'components/elements/buttons/CustomButton/CustomButton'
import SubColumn from './SubColumn'

import { capitalizeWord } from 'utils/functions'
import { popularPlansId, blackFridayPlansId, pricingStructure } from 'utils/constants'
import { InfoCircle as InfoIcon } from '../../../icons'
import { getPriceWithoutCents, getNormalizedPrice } from 'utils/functions'
import { ISubscriptionPlan } from 'models/pricing'
import { selectSubscriptionProcessing } from 'store/pricing/selectors'
import { selectSubscriptionInfo } from 'store/user/selectors'
import { ProxyPoolDescription } from 'utils/proxy/proxyConstants'

interface IPlansComparisonTable {
  plans: any[]
  activePriceType: string
  className?: string
  processingPlanId?: string | null
  onPlanSelect: (plan: ISubscriptionPlan) => void
}

const PlansComparisonTable: React.FC<IPlansComparisonTable> = (props) => {
  const { plans, activePriceType, processingPlanId, onPlanSelect, className } =
    props

  const [isCommerceOpened, toggleCommerce] = useState(false)
  const [isUnblockerOpened, toggleUnblocker] = useState(false)
  const [isSerpOpened, toggleSerp] = useState(false)
  const [isSocialOpened, toggleSocial] = useState(false)
  /*const [isBusinessOpened, toggleBusiness] = useState(false)
  const [isTicketsOpened, toggleTickets] = useState(false)*/

  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)
  const userSubscriptionInfo = useSelector(selectSubscriptionInfo)

  const isTrialAvailableForPlan = (plan: any) => {
    return !!plan.trial_price && plan.type && plan.type !== 'payg'
  }

  const renderButton = (plan: ISubscriptionPlan) => {
    let btnLabel: string
    let btnColor: ICustomButtonProps['color'] = 'outline'
    let isPermanentlyDisabled: boolean = false

    if (plan.id === userSubscriptionInfo?.plan_id) {
      btnLabel = 'Current plan'
      isPermanentlyDisabled = true
    } else if (isTrialAvailableForPlan(plan)) {
      if (!!plan.trial_price) {
        btnLabel = `Try for $${plan.trial_price}`
      } else {
        btnLabel = 'Get Free Trial'
      }
    } else {
      btnLabel = 'Subscribe'
    }

    if (!!popularPlansId.length && popularPlansId.includes(plan.id)) {
      btnColor = 'blue'
    } else if (!!blackFridayPlansId.length && blackFridayPlansId.includes(plan.id)) {
      btnColor = 'black'
    }

    return (
      <CustomButton
        size={'l'}
        color={btnColor}
        largeRadius={true}
        className={styles.planBtn}
        loading={plan.id === processingPlanId}
        disabled={
          subscriptionProcessing || isPermanentlyDisabled
        }
        onClick={() => handlePlanClick(plan)}
      >
        {btnLabel}
      </CustomButton>
    )
  }

  const getPlanPrice = (plan: any) => {
    return plan.type === 'payg'
      ? '15'
      : getPriceWithoutCents(plan?.amount[activePriceType])
  }

  const handlePlanClick = (plan: any) => {
    onPlanSelect(plan)
  }

  const handleCommerceToggle = () => {
    toggleCommerce(!isCommerceOpened)
  }

  const handleUnblockerToggle = () => {
    toggleUnblocker(!isUnblockerOpened)
  }

  const handleSerpToggle = () => {
    toggleSerp(!isSerpOpened)
  }

  const handleSocialToggle = () => {
    toggleSocial(!isSocialOpened)
  }

  /*const handleBusinessToggle = () => {
    toggleBusiness(!isBusinessOpened)
  }

  const handleTicketsToggle = () => {
    toggleTickets(!isTicketsOpened)
  }*/

  if (!plans?.length) return null

  const plansUpperList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-upper_${plan.id}`}
    >
      <div className={styles.colHeader}>
        <div className={styles.planHeader}>
          <div className={styles.planName}>
            <span>{capitalizeWord(plan.name)}</span>
          </div>
          <div className={styles.planPrice}>
            <div className={styles.priceMain}>${getPlanPrice(plan)}</div>

            <div className={styles.priceLabel}>
              {plan.type === 'payg' ? (
                <>
                  <p>&nbsp;starting</p>
                  <p>&nbsp;from</p>
                </>
              ) : (
                <>/mo.</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ))

  const scrapingPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-col_${plan.id}`}
    >
      <SubColumn
        items={pricingStructure.scraping.ecommerce}
        productKey={'ecommerce'}
        state={isCommerceOpened}
        plan={plan}
        handler={handleCommerceToggle}
      />

      <SubColumn
        items={pricingStructure.scraping.unblocker}
        productKey={'unblocker'}
        state={isUnblockerOpened}
        plan={plan}
        handler={handleUnblockerToggle}
      />

      <SubColumn
        items={pricingStructure.scraping.serp}
        productKey={'serp'}
        state={isSerpOpened}
        plan={plan}
        handler={handleSerpToggle}
      />

      <SubColumn
        items={pricingStructure.scraping.social}
        productKey={'social'}
        state={isSocialOpened}
        plan={plan}
        handler={handleSocialToggle}
      />
    </div>
  ))

  const proxyLabelsList = pricingStructure.proxy.values.map((type, index) => (
    <div className={styles.cell} key={`proxy-type-cell_${type.label}_${index}`}>
      {type.label}
    </div>
  ))

  const proxyPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-col_${plan.id}_${index}`}
    >
      {pricingStructure.proxy.values.map((type, index) => (
        <div
          className={styles.cell}
          key={`plans-comp-cell_${plan.id}_${index}-${type.productId}`}
        >
          ${getNormalizedPrice(plan.prices.monthly.proxy[type.productId])}
        </div>
      ))}
    </div>
  ))

  const renderProxyTable = () => {
    return (
      <>
        <div className={styles.subheader}>
          <div className={styles.tableCol}>
            <div className={styles.subheaderTitle}>
              {pricingStructure.proxy.label}
              <div
                className={styles.infoIconWrapper}
                data-tip
                data-tooltip-id={`comparison_table_proxy_info`}
              >
                <InfoIcon />
              </div>
            </div>
          </div>
          <div className={styles.tableCol}>
            <div className={styles.subheaderDescription}>Price per 1 GB</div>
          </div>
        </div>

        <div className={styles.table}>
          <div className={styles.tableCol}>{proxyLabelsList}</div>

          {proxyPriceList}
        </div>
      </>
    )
  }

  /*const unblockerPriceList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-col_${plan.id}`}
    >
      <div className={styles.cell}>$8.5</div>
    </div>
  ))*/

  const plansLowerList = plans.map((plan, index) => (
    <div
      className={`
        ${styles.tableCol}
        ${popularPlansId.includes(plan.id) ? styles.highlighted : ''}
      `}
      key={`plans-comp-lower_${plan.id}`}
    >
      <div className={styles.colFooter}>
        <div className={styles.planHeader}>
          <div className={styles.planName}>
            <span>{capitalizeWord(plan.name)}</span>
          </div>
          <div className={styles.planPrice}>
            <div className={styles.priceMain}>${getPlanPrice(plan)}</div>
            <div className={styles.priceLabel}>
              {plan.type === 'payg' ? (
                <>
                  <p>&nbsp;starting</p>
                  <p>&nbsp;from</p>
                </>
              ) : (
                <>/mo.</>
              )}
            </div>
          </div>
        </div>

        <div className={styles.btnWrapper}>
          {renderButton(plan)}
        </div>
      </div>
    </div>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <h3 className={styles.title}>Compare plans</h3>

      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <div
            className={`
          ${styles.table}
          ${styles.upperTable}
        `}
          >
            <div className={styles.tableCol}>
              <div className={styles.colHeader} />
            </div>

            {plansUpperList}
          </div>

          <div className={styles.subheader}>
            <div className={styles.tableCol}>
              <div className={styles.subheaderTitle}>Scraping APIs</div>
            </div>
            <div className={styles.tableCol}>
              <div className={styles.subheaderDescription}>
                Price per 1000 requests
                <div
                  className={styles.infoIconWrapper}
                  data-tip
                  data-tooltip-id={`scraping_price_notice`}
                >
                  <InfoIcon />
                </div>
              </div>

              <Tooltip id={`scraping_price_notice`} place={'bottom'}>
                The cost of using the Scraping API is calculated per every
                thousand requests. For example, if the price is listed as $2.10
                per 1000 requests, it means that 1 successful request to the
                selected API costs $0.0021
              </Tooltip>
            </div>
          </div>

          <div className={styles.table}>
            <div className={styles.tableCol}>
              <SubColumn
                title={'eCommerce'}
                items={pricingStructure.scraping.ecommerce}
                state={isCommerceOpened}
                handler={handleCommerceToggle}
              />

              <SubColumn
                title={'Unblocker'}
                items={pricingStructure.scraping.unblocker}
                state={isUnblockerOpened}
                handler={handleUnblockerToggle}
              />

              <SubColumn
                title={'SERP'}
                items={pricingStructure.scraping.serp}
                state={isSerpOpened}
                handler={handleSerpToggle}
              />

              <SubColumn
                title={'Social Networks'}
                items={pricingStructure.scraping.social}
                state={isSocialOpened}
                handler={handleSocialToggle}
              />
            </div>

            {scrapingPriceList}
          </div>

          {renderProxyTable()}

          {/*<div className={styles.subheader}>
        <div className={styles.tableCol}>
          <div className={styles.subheaderTitle}>Web Unblocker</div>
        </div>
        <div className={styles.tableCol}>
          <div className={styles.subheaderDescription}>Price per 1000 req.</div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.tableCol}>
          <div className={styles.cell}>Unblocker Standard</div>
        </div>

        {unblockerPriceList}
      </div>*/}

          <div
            className={`
          ${styles.table}
          ${styles.lowerTable}
        `}
          >
            <div className={styles.tableCol} />

            {plansLowerList}
          </div>
        </div>
      </div>

      <Tooltip id={`comparison_table_proxy_info`} place={'bottom'}>
        {ProxyPoolDescription}
      </Tooltip>
    </div>
  )
}

export default PlansComparisonTable
