import React, { InputHTMLAttributes, ReactNode, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Copy as CopyIcon } from '../../../icons'

import styles from './CustomInput.module.scss'

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  inputClassName?: string
  labelLeft?: string
  labelTop?: string
  error?: boolean
  warning?: string
  innerNotice?: string
  bottomNotice?: string
  copyable?: boolean
  icon?: ReactNode
  inputSize?: 'm' | 'l'
}

const CustomInput: React.FC<IInputProps> = (props) => {
  const {
    value,
    error,
    warning,
    className,
    inputClassName,
    labelLeft,
    labelTop,
    disabled,
    inputSize,
    icon,
    innerNotice,
    bottomNotice,
    copyable,
    ...rest
  } = props

  const inputSizeClass = inputSize ? `input_${inputSize}` : 'input_m'

  const [copiedVar, setCopiedVar] = useState<boolean | null>(null)

  if (copyable) {
    return (
      <CopyToClipboard
        text={value ? value.toString() : ''}
        onCopy={() => {
          setCopiedVar(true)
          setTimeout(() => setCopiedVar(false), 300)
        }}
      >
        <div
          className={`
            ${styles.container}
            ${styles.copyable}
            ${className ? className : ''}
          `}
        >
          <input
            className={`
            ${styles.input}
            ${styles.withIcon}
            ${styles[inputSizeClass]}
            ${!!error ? styles.errored : ''}
            ${disabled ? styles.disabled : ''}
            ${innerNotice ? styles.innerNoticed : ''}
            ${inputClassName ? inputClassName : ''}
          `}
            value={value}
            readOnly
            {...rest}
          />
          {!!icon && (
            <div className={styles.iconWrapper} data-testid="button-icon">
              {icon}
            </div>
          )}

          <div className={styles.iconWrapper} data-testid="button-icon">
            <CopyIcon />
          </div>

          <div
            className={`
          ${styles.copyTip}
          ${copiedVar ? styles.active : ''}
        `}
          >
            Copied
          </div>

          {!!innerNotice && (
            <div className={styles.innerNotice}>{innerNotice}</div>
          )}

          {!!bottomNotice && bottomNotice}
        </div>
      </CopyToClipboard>
    )
  } else {
    return (
      <div
        className={`
        ${styles.container}
        ${className ? className : ''}
      `}
      >
        <input
          className={`
            ${styles.input}
            ${styles[inputSizeClass]}
            ${!!icon ? styles.withIcon : ''}
            ${!!error ? styles.errored : ''}
            ${disabled ? styles.disabled : ''}
            ${innerNotice ? styles.innerNoticed : ''}
            ${inputClassName ? inputClassName : ''}
          `}
          value={value}
          {...rest}
        />
        {!!icon && (
          <div className={styles.iconWrapper} data-testid="button-icon">
            {icon}
          </div>
        )}

        {!!icon && (
          <div className={styles.iconWrapper} data-testid="button-icon">
            {icon}
          </div>
        )}

        {!!innerNotice && (
          <div className={styles.innerNotice}>{innerNotice}</div>
        )}
      </div>
    )
  }
}

export default CustomInput
