import React, { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'

import styles from './PlansList.module.scss'

import SubscriptionCard from 'components/modules/pricing/SubscriptionCard'
import InfoNotice from 'components/elements/blocks/InfoNotice'

import { ISubscriptionPlan, TProxyTypes } from 'models/pricing'
import { popularPlansId, blackFridayPlansId } from 'utils/constants'
import { selectSubscriptionProcessing } from 'store/pricing/selectors'
import { selectSubscriptionInfo } from 'store/user/selectors'
import { InfoRect as InfoIcon } from '../../../icons'

interface IPlansListProps {
  plansList: any[]
  // activePriceType: 'monthly' | 'quarterly' | 'annualy'
  activePriceType: string
  isEnterpriseActive: boolean
  className?: string
  processingPlanId?: string | null
  onPlanSelect: (plan: ISubscriptionPlan) => void
}

const customOfferPlan = {
  id: 'custom',
  name: 'Custom Offer',
  amount: {
    monthly: '5999+',
    quarterly: '5999+',
    annualy: '5999+',
  },
  type: 'general',
  prices: {
    monthly: {
      proxy: {
        wifi: '0.35',
      },
      ecommerce: {
        example: '0.6',
      },
      social: {
        example: '0.6',
      },
      serp: {
        example: '0.6',
      },
    },
  },
  trial_price: 1.99,
}

const PlansList: React.FC<IPlansListProps> = (props) => {
  const {
    plansList,
    isEnterpriseActive,
    activePriceType,
    onPlanSelect,
    className,
    processingPlanId,
  } = props
  const { show } = useIntercom()

  const [activePlanType /*, setActivePlanType*/] = useState<TProxyTypes | null>(
    null
  )

  const subscriptionProcessing = useSelector(selectSubscriptionProcessing)
  const userSubscriptionInfo = useSelector(selectSubscriptionInfo)

  const checkIsTrialActual = (plans: ISubscriptionPlan) => {
    let isTrialAvailable = false

    for (let i = 0; i < plans.length; i++) {
      if (plans[i].trial_price) {
        isTrialAvailable = true
      }
    }

    return isTrialAvailable
  }

  const isTrialActual = !!plansList ? checkIsTrialActual(plansList) : false

  const handleShowIntercom = () => show()

  const handlePlanSelect = (plan: ISubscriptionPlan) => {
    onPlanSelect(plan)
  }


  const blackFridayDescription = <>Activate this plan <b>from 11.11 to 9.12</b> to get double credits within 24 hours! Unused bonus credits will expire after one month.</>

  const renderPlansItemsList = () => {
    if (!!plansList.length) {
      return plansList.map((plan: ISubscriptionPlan) => (
        <SubscriptionCard
          key={plan.id}
          plan={plan}
          onPlanClick={handlePlanSelect}
          activePriceType={activePriceType}
          isTrialActual={isTrialActual}
          subscriptionProcessing={subscriptionProcessing}
          promoLabel={popularPlansId.includes(plan.id) ? 'MOST POPULAR' : null}
          altPromoLabel={
            blackFridayPlansId.includes(plan.id) ? 'BLACK FRIDAY x2' : null
          }
          altPromoDescription={
            blackFridayPlansId.includes(plan.id) ? blackFridayDescription : null
          }
          planProcessing={plan.id === processingPlanId}
          activePlanType={activePlanType}
          activeSubscriptionPlanId={userSubscriptionInfo?.plan_id}
          className={styles.planItem}
        />
      ))
    } else {
      return null
    }
  }

  /*if (plansFetching || trialAvailabilityChecking) {
    return <Spinner classname={styles.spinnerContainer} />
  }*/

  if (!plansList?.length) return null

  return (
    <section
      className={`
        ${styles.plansSection}
        ${className || ''}
      `}
    >
      <div className={styles.plansWrapper}>
        <div className={styles.plansRow}>
          {renderPlansItemsList()}
          {isEnterpriseActive && (
            <SubscriptionCard
              plan={customOfferPlan}
              onPlanClick={handleShowIntercom}
              isCustomPlan={true}
              className={styles.planItem}
            />
          )}
        </div>
      </div>

      {isTrialActual && (
        <InfoNotice
          color={'blue'}
          icon={<InfoIcon />}
          className={styles.autoTopUpNotice}
        >
          With $1.99 Trial you can test Proxy with 200 MB traffic (eq. $10/GB)
          or Scraping API with 180 scraping API requests (eq. $11/1000 req.)
        </InfoNotice>
      )}
    </section>
  )
}

export default PlansList
