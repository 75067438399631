export enum PricingActionsTypes {
  PRICING_GET_PLANS = 'PRICING_GET_PLANS',
  PRICING_GET_PLANS_SUCCESS = 'PRICING_GET_PLANS_SUCCESS',
  PRICING_GET_PLANS_FAIL = 'PRICING_GET_PLANS_FAIL',

  PRICING_GET_PRODUCT_PRICE = 'PRICING_GET_PRODUCT_PRICE',
  PRICING_GET_PRODUCT_PRICE_SUCCESS = 'PRICING_GET_PRODUCT_PRICE_SUCCESS',
  PRICING_GET_PRODUCT_PRICE_FAIL = 'PRICING_GET_PRODUCT_PRICE_FAIL',

  PRICING_TOGGLE_TOP_UP_MODAL = 'PRICING_TOGGLE_TOP_UP_MODAL',

  PRICING_TOP_UP_BALANCE = 'PRICING_TOP_UP_BALANCE',
  PRICING_TOP_UP_BALANCE_SUCCESS = 'PRICING_TOP_UP_BALANCE_SUCCESS',
  PRICING_TOP_UP_BALANCE_FAIL = 'PRICING_TOP_UP_BALANCE_FAIL',

  PRICING_CANCEL_AUTO_TOP_UP = 'PRICING_CANCEL_AUTO_TOP_UP',
  PRICING_CANCEL_AUTO_TOP_UP_SUCCESS = 'PRICING_CANCEL_AUTO_TOP_UP_SUCCESS',
  PRICING_CANCEL_AUTO_TOP_UP_FAIL = 'PRICING_CANCEL_AUTO_TOP_UP_FAIL',

  PRICING_GET_INVOICE = 'PRICING_GET_INVOICE',
  PRICING_GET_INVOICE_SUCCESS = 'PRICING_GET_INVOICE_SUCCESS',
  PRICING_GET_INVOICE_FAIL = 'PRICING_GET_INVOICE_FAIL',

  PRICING_RESET_INVOICE = 'PRICING_RESET_INVOICE',

  PRICING_SHOW_INVOICE_MODAL = 'PRICING_SHOW_INVOICE_MODAL',
  PRICING_HIDE_INVOICE_MODAL = 'PRICING_HIDE_INVOICE_MODAL',

  PRICING_SHOW_BILLING_FORM = 'PRICING_SHOW_BILLING_FORM',
  PRICING_HIDE_BILLING_FORM = 'PRICING_HIDE_BILLING_FORM',

  PRICING_VALIDATE_COUPON = 'PRICING_VALIDATE_COUPON',
  PRICING_VALIDATE_COUPON_SUCCESS = 'PRICING_VALIDATE_COUPON_SUCCESS',
  PRICING_VALIDATE_COUPON_FAIL = 'PRICING_VALIDATE_COUPON_FAIL',

  PRICING_SET_COUPON_VALIDATION_ERROR = 'PRICING_SET_COUPON_VALIDATION_ERROR',

  PRICING_ACTIVATE_FULL_SUBSCRIPTION = 'PRICING_ACTIVATE_FULL_SUBSCRIPTION',
  PRICING_ACTIVATE_FULL_SUBSCRIPTION_SUCCESS = 'PRICING_ACTIVATE_FULL_SUBSCRIPTION_SUCCESS',
  PRICING_ACTIVATE_FULL_SUBSCRIPTION_FAIL = 'PRICING_ACTIVATE_FULL_SUBSCRIPTION_FAIL',

  PRICING_CANCEL_SUBSCRIPTION = 'PRICING_CANCEL_SUBSCRIPTION',
  PRICING_CANCEL_SUBSCRIPTION_SUCCESS = 'PRICING_CANCEL_SUBSCRIPTION_SUCCESS',
  PRICING_CANCEL_SUBSCRIPTION_FAIL = 'PRICING_CANCEL_SUBSCRIPTION_FAIL',
}
