const name = 'userReducer'

export const selectInitialLoading = (state: any) => state[name].initialLoading
export const selectUserInitStatus = (state: any) => state[name].isUserInitFailed
export const selectSubscriptionInfo = (state: any) =>
  state[name].subscriptionInfo
export const selectSubscriptionInfoFetching = (state: any) =>
  state[name].subscriptionInfoFetching
export const selectBalanceInfo = (state: any) => state[name].balanceInfo
export const selectBalanceInfoFetching = (state: any) =>
  state[name].balanceInfoFetching
export const selectOverviewFetching = (state: any) =>
  state[name].overviewFetching
export const selectUserOverviewUrl = (state: any) => state[name].userOverviewUrl
export const selectUserFirstName = (state: any) => state[name].firstName
export const selectUserLastName = (state: any) => state[name].lastName
export const selectUserEmail = (state: any) => state[name].email
export const selectTempUserFirstName = (state: any) => state[name].tempFirstName
export const selectTempUserLastName = (state: any) => state[name].tempLastName
export const selectScrapperApiKey = (state: any) => state[name].scrapperApiKey
export const selectScrapperApiKeyFetching = (state: any) =>
  state[name].scrapperApiKeyFetching
export const selectProxyPackages = (state: any) => state[name].proxyPackages
export const selectProxyPackagesFetching = (state: any) =>
  state[name].proxyPackagesFetching
export const selectIntercomUserHash = (state: any) => state[name].intercomHash
export const selectUserHash = (state: any) => state[name].userHash
