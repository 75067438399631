import * as React from 'react'
import type { SVGProps } from 'react'
const SvgWifi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.604 8.704c.328.337.85.37 1.203.066a8.03 8.03 0 0 1 10.376-.006.89.89 0 0 0 1.216-.06.956.956 0 0 0-.064-1.388 9.82 9.82 0 0 0-12.667 0 .95.95 0 0 0-.064 1.388m4.992 5.13.945.973c.251.258.657.258.907 0l.946-.972c.303-.311.238-.847-.148-1.052a2.69 2.69 0 0 0-2.515 0c-.367.205-.437.741-.135 1.052m-2.4-2.465a.89.89 0 0 0 1.178.085 4.44 4.44 0 0 1 5.249 0 .9.9 0 0 0 1.177-.085l.006-.007c.386-.397.36-1.071-.083-1.395a6.235 6.235 0 0 0-7.45 0c-.443.33-.469.998-.077 1.402"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgWifi
