import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import MainHeader from 'components/navigation/MainHeader'
import ProxyContent from 'components/modules/proxy/ProxyContent'
import SubscriptionInfo from 'components/modules/settings/SubscriptionsInfo'

import { selectSubscriptionInfo } from 'store/user/selectors'
import { UserActionsTypes } from 'store/user/actions'
import { isSubscriptionWorking } from 'utils/functions'

const ProxyPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'My products',
      to: '/',
    },
    {
      label: 'Proxies',
      isActive: true,
    },
  ]

  const dispatch = useDispatch()

  const subscriptionInfo = useSelector(selectSubscriptionInfo)

  useEffect(() => {
    if (subscriptionInfo?.plan) {
      dispatch({ type: UserActionsTypes.USER_GET_PROXY_PACKAGES })
    }
  }, [])

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} />

      {isSubscriptionWorking(subscriptionInfo) ? (
        <ProxyContent />
      ) : (
        <SubscriptionInfo reducedDisplayMode />
      )}
    </>
  )
}

export default ProxyPage
