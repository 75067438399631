import React from 'react'
import { useNavigate } from 'react-router-dom'

import CustomButton from 'components/elements/buttons/CustomButton'

import styles from './ProductCard.module.scss'

export interface IProduct {
  title: string
  description: string | JSX.Element
  prices?: (string | JSX.Element)[]
  link: string
}

interface IPricingProductCardProps {
  product: IProduct
  isFullFunctional?: boolean
  className?: string
}

const ProductCard: React.FC<IPricingProductCardProps> = (props) => {
  const { product, className, isFullFunctional } = props
  const navigate = useNavigate()

  const pricesList = product.prices?.map((price, index) => {
    return (
      <div className={styles.price} key={`pricing-product-card-price_${index}`}>
        <li>{price}</li>
      </div>
    )
  })

  const handleBtnClick = () => {
    navigate(product.link)
  }

  return (
    <div
      className={`
        ${styles.card}
        ${className || ''}
      `}
    >
      <div className={styles.title}>{product.title}</div>

      <div className={styles.description}>{product.description}</div>

      {!!product.prices && (
        <div className={styles.prices}>
          <div className={styles.pricesTitle}>Pricing starts from:</div>

          <ul className={styles.featuresList}>{pricesList}</ul>
        </div>
      )}

      <CustomButton onClick={handleBtnClick} wide disabled={!isFullFunctional}>
        Use product
      </CustomButton>
    </div>
  )
}

export default ProductCard
