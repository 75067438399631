import React from 'react'
import ReactDOM from 'react-dom/client'
import { Factor } from '@slashid/slashid'
import { SlashIDProvider, ConfigurationProvider } from '@slashid/react'

import './index.css'
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import { initAmplitude } from './utils/analytics'

const REACT_APP_SLASH_ID_ORGANIZATION =
  process.env.REACT_APP_SLASH_ID_ORGANIZATION || ''

const factors: Factor[] = [
  {
    method: 'oidc',
    options: {
      provider: 'google', // google, facebook, github...
      client_id:
        '933931600470-mg278re5tdsi0d258iaknip2hl2fndkk.apps.googleusercontent.com',
      ux_mode: 'popup', // or "redirect"
    },
  },
  { method: 'otp_via_email' },
]

initAmplitude()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <SlashIDProvider
    oid={REACT_APP_SLASH_ID_ORGANIZATION}
    tokenStorage="localStorage"
  >
    <ConfigurationProvider factors={factors}>
      <App />
    </ConfigurationProvider>
  </SlashIDProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
