import React from 'react'
import { Tooltip } from 'react-tooltip'

import { CaretRight as CaretRightIcon, Beta as BetaIcon } from '../../icons'
import BreadcrumbItem from './BreadcrumbItem'

import styles from './Breadcrumbs.module.scss'

interface IBreadcrumbsItemProps {
  item: {
    label: string
    to?: string
    onClick?: () => void
  }
  className?: string
}

export interface IBreadcrumbsProps {
  items: IBreadcrumbsItemProps['item'][]
  className?: string
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
  const { items, className } = props

  const itemsList =
    !!items && !!items.length
      ? items.map((item, index) => (
          <React.Fragment key={index}>
            <BreadcrumbItem item={item} isLast={index + 1 === items.length} />
            {index + 1 < items.length ? (
              <div className={styles.arrowWrapper}>
                <CaretRightIcon />
              </div>
            ) : null}
          </React.Fragment>
        ))
      : null

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div
        className={styles.infoIconWrapper}
        data-tip
        data-tooltip-id={`beta-dash-info`}
      >
        <BetaIcon className={styles.betaIcon} />
        version
      </div>

      <Tooltip id={`beta-dash-info`} place={'bottom'}>
        This is a beta version of our dashboard, including a better experience
        for setting up proxies and a high-end constructor to create Scraping API
        requests. Some features may not be accessible in this beta and will be
        available soon.
      </Tooltip>

      {itemsList}
    </div>
  )
}

export default Breadcrumbs
