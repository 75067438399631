const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || ''

const amplitude = window.amplitude

const amplitudeOptions = {
  defaultTracking: true,
  serverUrl: 'https://ampl.soax.com/amplitude',
}

export const initAmplitude = () => {
  if (!!window.amplitude && !!window.sessionReplay) {
    window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1.0 }))
  }
  amplitude.init(AMPLITUDE_API_KEY, amplitudeOptions)
}

export const logAmplitudeEvent = (eventType, eventProperties) => {
  if (!!window.amplitude) amplitude.track(eventType, eventProperties)
}

export const setAmplitudeUserId = (userId) => {
  if (!!window.amplitude) {
    amplitude.setUserId(userId)
  }
}

export const resetAmplitude = () => {
  if (!!window.amplitude) {
    amplitude.reset()
  }
}
