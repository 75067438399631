import React from 'react'

import styles from './TitledCard.module.scss'

interface ITitledCardProps {
  title: string
  children: React.ReactNode
  className?: string
}

const TitledCard: React.FC<ITitledCardProps> = (props) => {
  const { title, children, className } = props

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  )
}

export default TitledCard
