import * as React from 'react'
import type { SVGProps } from 'react'
const SvgBeta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#3B44A1"
        stroke="#3B44A1"
        strokeWidth={0.4}
        d="M7.77 5.164a2.22 2.22 0 0 0 .855-1.789c0-1.324-1.08-2.25-2.625-2.25A2.487 2.487 0 0 0 3.375 3.75v7.875h.75V9.42a3.06 3.06 0 0 0 2.25.93 2.77 2.77 0 0 0 2.869-2.854A2.48 2.48 0 0 0 7.77 5.164ZM6.375 9.6a2.13 2.13 0 0 1-2.246-2.006c-.004-.03-.004-.064-.004-.094V3.75A1.758 1.758 0 0 1 6 1.875c1.14 0 1.875.589 1.875 1.5a1.45 1.45 0 0 1-1.402 1.5h-.098v.75c1.286 0 2.119.735 2.119 1.875a2.023 2.023 0 0 1-2.119 2.1Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgBeta
