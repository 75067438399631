import { combineReducers } from 'redux'

import pricingReducer from './pricing/reducer'
import userReducer from './user/reducer'
import serviceReducer from './service/reducer'

const createRootReducer = () =>
  combineReducers({
    pricingReducer,
    userReducer,
    serviceReducer,
  })

export default createRootReducer
