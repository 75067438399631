import React, { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styles from './InvoiceModal.module.scss'

import capterra from 'assets/images/reviews/capterra.png'
import g2 from 'assets/images/reviews/g2.png'
import Loader from 'components/elements/progress/Loader'
import CustomModal from 'components/elements/modal/CustomModal'
import CustomButton from '../../../elements/buttons/CustomButton'

import {
  selectInvoiceUrl,
  selectInvoiceModalStatus,
} from 'store/pricing/selectors'
import { PricingActionsTypes } from 'store/pricing/actions'
import { UserActionsTypes } from 'store/user/actions'

const InvoiceModal: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const invoiceUrl = useSelector(selectInvoiceUrl)
  const inInvoiceModalShown = useSelector(selectInvoiceModalStatus)

  const [wasInvoiceClicked, toggleInvoiceClicked] = useState(false)

  const { show } = useIntercom()

  const handleSupport = () => {
    show()
  }

  const handleReturn = () => {
    dispatch({ type: PricingActionsTypes.PRICING_HIDE_INVOICE_MODAL })
    dispatch({ type: PricingActionsTypes.PRICING_RESET_INVOICE })
    dispatch({ type: UserActionsTypes.USER_GET_BALANCE })
    dispatch({ type: UserActionsTypes.USER_GET_SUBSCRIPTIONS })

    toggleInvoiceClicked(false)

    navigate('/')
  }

  const handleClose = () => {
    dispatch({ type: PricingActionsTypes.PRICING_HIDE_INVOICE_MODAL })
    dispatch({ type: PricingActionsTypes.PRICING_RESET_INVOICE })
    dispatch({ type: UserActionsTypes.USER_GET_BALANCE })
    dispatch({ type: UserActionsTypes.USER_GET_SUBSCRIPTIONS })

    toggleInvoiceClicked(false)
  }

  const handlePaymentClick = () => {
    window.open(invoiceUrl, '_blank', 'noopener,noreferrer')

    toggleInvoiceClicked(true)
  }

  const renderContent = () => {
    if (!invoiceUrl) {
      return (
        <div className={styles.main}>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
          <div className={styles.descriptionBlock}>
            <p className={styles.description}>Generating new invoice...</p>
            <p className={styles.description}>
              If it goes longer than 1 min.{' '}
              <span onClick={handleSupport} className={styles.supportLink}>
                contact support in chat
              </span>
            </p>
          </div>
        </div>
      )
    } else if (!wasInvoiceClicked) {
      return (
        <div className={styles.main}>
          <div className={styles.descriptionBlock}>
            <p className={styles.description}>Invoice is ready to pay</p>
          </div>
          <CustomButton
            className={styles.submitBtn}
            color={'blue'}
            onClick={handlePaymentClick}
          >
            Go to payment
          </CustomButton>
        </div>
      )
    } else {
      return (
        <div className={styles.main}>
          <div className={styles.descriptionBlock}>
            <p className={styles.description}>Processing will take some time</p>
          </div>
          <CustomButton
            className={styles.submitBtn}
            color={'blue'}
            onClick={handleReturn}
          >
            Return to Dashboard
          </CustomButton>
        </div>
      )
    }
  }

  return (
    <CustomModal
      title={'Payment in progress'}
      isModalOpen={inInvoiceModalShown}
      shouldCloseOnOverlayClick
      showCross
      modalWidth={'560px'}
      className={styles.modalContent}
      onModalClose={handleClose}
    >
      {renderContent()}

      <div className={styles.reviews}>
        <img className={styles.reviewItem} src={capterra} alt={'review'} />
        <img className={styles.reviewItem} src={g2} alt={'review'} />
      </div>
    </CustomModal>
  )
}

export default InvoiceModal
