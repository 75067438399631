import React, { ReactElement } from 'react'

import styles from './PromoBlock.module.scss'

interface IPromoItem {
  label: string
  icon: ReactElement
}

interface IPromoBlockProps {
  className?: string
  title: string
  image: string
  items?: IPromoItem[]
  children?: React.ReactNode
}

const PromoBlock: React.FC<IPromoBlockProps> = (props) => {
  const { className, title, image, items, children } = props

  const itemsList = items?.map((item, index) => (
    <div className={styles.item} key={`promo-block-item_${index}`}>
      <div className={styles.itemIconWrapper}>{item.icon}</div>
      {item.label}
    </div>
  ))

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.title}>{title}</div>

      {!!children && <div className={styles.content}>{children}</div>}

      {!!items && <div className={styles.itemsList}>{itemsList}</div>}
    </div>
  )
}

export default PromoBlock
