import { all, fork } from 'redux-saga/effects'

import pricingSaga from './pricing/saga'
import userSaga from './user/saga'
import serviceSaga from './service/saga'

const rootSaga = function* () {
  yield all([fork(pricingSaga), fork(userSaga), fork(serviceSaga)])
}

export default rootSaga
