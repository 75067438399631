import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from 'react-router-dom'
import { useSlashID } from '@slashid/react'
import { IdFlowStartedEvent } from '@slashid/slashid'

import SignUpPage from 'pages/auth/SIgnUpPage'
import SignInPage from 'pages/auth/SignInPage'
import { logAmplitudeEvent } from '../utils/analytics'

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  )
}

const RouterContent = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { sid, sdkState } = useSlashID()

  useEffect(() => {
    if (searchParams.has('logout')) {
      searchParams.delete('logout')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    // ensure the SDK is ready
    if (sdkState === 'ready' && sid) {
      // TypeScript will help here - in the payload you'll know what auth method was used to initiate the flow
      const handleFlowStarted = (event: IdFlowStartedEvent) => {
        if (
          !!event.authenticationFactor?.method &&
          event.authenticationFactor?.method === 'oidc'
        ) {
          const form =
            event.authenticationFactor?.options?.redirect_target?.includes(
              'sign-in'
            )
              ? 'sign_in'
              : 'sign_up'

          logAmplitudeEvent('login clicked', {
            form: form,
            authMethod: 'google',
          })
        }
      }

      sid.subscribe('idFlowStarted', handleFlowStarted)

      // clean up on exit
      return () => sid.unsubscribe('idFlowStarted', handleFlowStarted)
    }
  }, [sdkState, sid])

  return (
    <Routes>
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="*" element={<Navigate to="/sign-in" replace />} />
    </Routes>
  )
}

export default RoutesComponent
