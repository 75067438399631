import React from 'react'

import styles from './InitialLoadingScreen.module.scss'

import Loader from '../Loader'

const InitialLoadingScreen: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
      <div className={styles.text}>Loading SOAX dashboard...</div>
    </div>
  )
}

export default InitialLoadingScreen
