import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import styles from './ScrapingContent.module.scss'

import TabsList from 'components/elements/tabs/TabsList'
import TabPanels from 'components/elements/tabs/TabPanels'
import ScrapingBuilder from '../ScrapingBuilder'
import ScrapingDocs from '../ScrapingDocs'
// import ScrapingStatistics from '../ScrapingStatistics'
import { ITabItem } from 'components/elements/tabs/TabsList/TabsList'

const ScrapingContent: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activeTabParam = searchParams.get('active_tab')

  const tabItems = [
    {
      label: 'Request builder',
      value: 'request_builder',
    },
    {
      label: 'API documentation',
      value: 'api_keys',
    },
    /*{
      label: 'Statistics',
      value: 'statistics',
    },*/
  ]

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleTabChange = (index: number, item?: ITabItem) => {
    setActiveTabIndex(index)

    if (item?.value) setSearchParams({ active_tab: item.value })
  }

  const tabTypes = tabItems.map((item) => item.value)

  useEffect(() => {
    const activeSettingsParamIndex = activeTabParam
      ? tabTypes.indexOf(activeTabParam)
      : null

    if (
      activeSettingsParamIndex &&
      activeSettingsParamIndex !== activeTabIndex
    ) {
      setActiveTabIndex(activeSettingsParamIndex)
    }
  }, [tabTypes, activeTabParam, activeTabIndex])

  const tabContentArray = [
    <ScrapingBuilder />,
    <ScrapingDocs />,
    /*<ScrapingStatistics />,*/
  ]

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <TabsList
          items={tabItems}
          activeTabIndex={activeTabIndex}
          onChange={handleTabChange}
        />
      </div>
      <div className={styles.content}>
        <TabPanels items={tabContentArray} activeTabIndex={activeTabIndex} />
      </div>
    </section>
  )
}

export default ScrapingContent
