import React, { useCallback, useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'

import styles from './ProxyConfigurationForm.module.scss'

import {
  randomCountry,
  randomRegion,
  randomCity,
  randomIsp,
} from 'utils/constants'
import {
  selectLocations,
  selectLocationTargets,
  selectLocationTargetsFetching,
} from 'store/service/selectors'
import { capitalizeWord } from 'utils/functions'
import {
  ispAvailableCountries,
  dcAvailableCountries,
} from 'utils/proxy/proxyConstants'

import { InfoCircle as InfoIcon } from '../../../icons'
import Flag from 'components/elements/other/Flag'
import CustomSelect from 'components/elements/inputs/CustomSelect'
import InputGroup from 'components/elements/forms/InputGroup'
import CustomCheckbox from 'components/elements/inputs/CustomCheckbox'
import CustomInput from 'components/elements/inputs/CustomInput'
import CustomRadio from 'components/elements/inputs/CustomRadio'
import Loader from 'components/elements/progress/Loader'
import { ISelectOption } from 'components/elements/inputs/CustomSelect/CustomSelect'

interface IProxyConfigurationFormProps {
  selectedProxyType: any
  selectedTimeInterval: any
  selectedCountry: any
  selectedRegion: any
  selectedCity: any
  selectedIsp: any
  isBindIPActive: boolean
  activeSessionLengthType: any
  sessionPrefix: string
  connectionsAmount?: number
  isConnectionsShown?: boolean
  availableTimeIntervals: ISelectOption[]
  onTimeIntervalChange: (option: ISelectOption) => void
  onCountryChange: (option: ISelectOption) => void
  onRegionChange: (option: ISelectOption) => void
  onCityChange: (option: ISelectOption) => void
  onIspChange: (option: ISelectOption) => void
  onBindIp: () => void
  onActiveSessionLengthChange: (e: any) => void
  onSessionPrefixChange: (e: any) => void
  onConnectionsAmountChange?: (e: any) => void
}

const ProxyConfigurationForm: React.FC<IProxyConfigurationFormProps> = (
  props
) => {
  const {
    selectedProxyType,
    selectedCountry,
    selectedRegion,
    selectedCity,
    selectedIsp,
    activeSessionLengthType,
    selectedTimeInterval,
    isBindIPActive,
    isConnectionsShown,
    sessionPrefix,
    connectionsAmount,
    availableTimeIntervals,
    onConnectionsAmountChange,
    onCountryChange,
    onRegionChange,
    onCityChange,
    onIspChange,
    onActiveSessionLengthChange,
    onTimeIntervalChange,
    onBindIp,
    onSessionPrefixChange,
  } = props

  const allCountries = useSelector(selectLocations)
  const activeLocationTargets = useSelector(selectLocationTargets)
  const deepTargetsFetching = useSelector(selectLocationTargetsFetching)

  const getCountriesOptions = useCallback(() => {
    const options = [randomCountry]

    let availableLocations

    if (selectedProxyType.value === 'dc') {
      availableLocations = dcAvailableCountries
    } else if (selectedProxyType.value === 'isp') {
      availableLocations = ispAvailableCountries
    } else {
      availableLocations = allCountries
    }

    if (availableLocations) {
      Object.values(availableLocations).forEach((location: any) => {
        options.push({
          label: location.name,
          value: location.code,
        })
      })
    }

    return options
  }, [allCountries, selectedProxyType])

  const countriesOptions = useMemo(
    () => getCountriesOptions(),
    [getCountriesOptions]
  )

  const getOptions = (values: string[], defaultOption: ISelectOption) => {
    const options = [defaultOption]

    if (values) {
      values.forEach((value: string) => {
        options.push({
          label: capitalizeWord(value),
          value: value,
        })
      })
    }

    return options
  }

  const regionOptions = getOptions(activeLocationTargets?.regions, randomRegion)
  const citiesOptions = getOptions(activeLocationTargets?.cities, randomCity)
  const ispOptions = getOptions(activeLocationTargets?.isp, randomIsp)

  const iconedCountriesOptions = countriesOptions.map((item) => ({
    ...item,
    icon:
      item.value === 'any' ? null : (
        <Flag countryCode={item.value} className={styles.optionFlag} />
      ),
  }))

  const [isDeepTargetsActive, toggleDeepTargets] = useState(false)

  const shouldDeepTargetBeVisible = selectedProxyType.value !== 'isp'

  const handleDeepTargets = () => {
    if (isDeepTargetsActive) {
      onRegionChange(randomRegion)
      onCityChange(randomCity)
      onIspChange(randomIsp)
    }

    toggleDeepTargets(!isDeepTargetsActive)
  }

  const handleCountryChange = (option: any) => {
    onCountryChange(option)
  }

  const handleRegionChange = (option: any) => {
    onRegionChange(option)
  }

  const handleCityChange = (option: any) => {
    onCityChange(option)
  }

  const handleIspChange = (option: any) => {
    onIspChange(option)
  }

  const handleTimeIntervalChange = (option: any) => {
    onTimeIntervalChange(option)
  }

  const handleActiveSessionLengthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'new' && isBindIPActive) {
      onBindIp()
    }

    onActiveSessionLengthChange(e.target.value)
  }

  const handleBindIp = () => {
    if (activeSessionLengthType === 'new' && !isBindIPActive) {
      onActiveSessionLengthChange('same')
    }

    onBindIp()
  }

  return (
    <div className={styles.form}>
      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>1</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup
            labelTop={'Target location'}
            className={styles.targetGroup}
          >
            <CustomSelect
              onChange={handleCountryChange}
              value={selectedCountry}
              name={'quick-connect-target-country'}
              options={iconedCountriesOptions}
              disabled={selectedProxyType.value === 'isp'}
            />
          </InputGroup>

          {shouldDeepTargetBeVisible && (
            <>
              <div className={styles.deepTargetHeader}>
                <CustomCheckbox
                  id={'deep-targets-toggle'}
                  checked={isDeepTargetsActive}
                  onChange={handleDeepTargets}
                >
                  Deep targets levels
                </CustomCheckbox>

                <div
                  className={`
                    ${styles.deepTargetLoaderContainer}
                    ${deepTargetsFetching ? styles.active : ''}
                  `}
                >
                  <Loader />
                </div>
              </div>

              <div
                className={`
                  ${styles.deepTargetBlock}
                  ${isDeepTargetsActive ? styles.active : ''}
                `}
              >
                <CustomSelect
                  onChange={handleRegionChange}
                  value={selectedRegion}
                  name={'quick-connect-target-region'}
                  options={regionOptions}
                  className={styles.deepTargetSelect}
                  disabled={selectedCountry.value === 'any'}
                />

                <CustomSelect
                  onChange={handleCityChange}
                  value={selectedCity}
                  name={'quick-connect-target-city'}
                  options={citiesOptions}
                  className={styles.deepTargetSelect}
                  disabled={selectedCountry.value === 'any'}
                />

                <CustomSelect
                  onChange={handleIspChange}
                  value={selectedIsp}
                  name={'quick-connect-target-isp'}
                  options={ispOptions}
                  className={styles.deepTargetSelect}
                  disabled={selectedCountry.value === 'any'}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>2</div>
            <div className={styles.formLine} />
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup labelTop={'Session length (IP refresh rate)'}>
            <div className={styles.groupInnerBlock}>
              <div className={styles.radioWrapper}>
                <CustomRadio
                  id={'same-ip-radio'}
                  name={'session-length'}
                  value={'same'}
                  checked={activeSessionLengthType === 'same'}
                  onChange={handleActiveSessionLengthChange}
                >
                  Same IP up to
                </CustomRadio>

                <CustomSelect
                  className={styles.timeIntervalSelect}
                  type={'ghost'}
                  value={selectedTimeInterval}
                  name={'time-interval-select'}
                  options={availableTimeIntervals}
                  onChange={handleTimeIntervalChange}
                />

                <div className={styles.bindIpBlock}>
                  <CustomCheckbox
                    id={'bind_ip_toggle'}
                    checked={isBindIPActive}
                    onChange={handleBindIp}
                  >
                    Bind IP
                  </CustomCheckbox>

                  <div
                    className={styles.infoIconWrapper}
                    data-tip
                    data-tooltip-id={`bind_ip_notice`}
                  >
                    <InfoIcon />
                  </div>
                </div>
              </div>

              <div className={styles.radioWrapper}>
                <CustomRadio
                  id={'new-ip-radio'}
                  name={'session-length'}
                  value={'new'}
                  checked={activeSessionLengthType === 'new'}
                  onChange={handleActiveSessionLengthChange}
                >
                  New IP on each request
                </CustomRadio>
              </div>
            </div>
          </InputGroup>
        </div>

        <Tooltip id={`bind_ip_notice`} place={'bottom'}>
          <p>
            Empty state - IP will refresh after selected period. If IP goes
            offline earlier, you will be reconnected with a new one immediately.
          </p>
          <br />
          <p>
            Filled state - IP will refresh only after selected period. If it
            goes offline earlier, you will not be reconnected with new IP -
            Sticky IP mode.
          </p>
        </Tooltip>
      </div>

      <div className={styles.formBlock}>
        <div>
          <div className={styles.formLeft}>
            <div className={styles.formNumber}>3</div>
            {isConnectionsShown && <div className={styles.formLine} />}
          </div>
        </div>

        <div className={styles.formRight}>
          <InputGroup
            labelTop={'Session ID prefix'}
            name={'session_id_input_group'}
            description={
              <>
                <b>А random string</b> to create a session in order to keep the
                same proxy for more than one request. Example: <b>rand123456</b>
              </>
            }
          >
            <CustomInput
              value={sessionPrefix}
              readOnly={activeSessionLengthType === 'new'}
              onChange={onSessionPrefixChange}
            />
          </InputGroup>
        </div>
      </div>

      {isConnectionsShown && (
        <div className={styles.formBlock}>
          <div>
            <div className={styles.formLeft}>
              <div className={styles.formNumber}>4</div>
            </div>
          </div>

          <div className={styles.formRight}>
            <InputGroup
              labelTop={'Connections amount'}
              childrenDirection={'vertical'}
            >
              <CustomInput
                value={connectionsAmount}
                type={'number'}
                onChange={onConnectionsAmountChange}
              />
              <span className={styles.inputGroupBottomNotice}>1000 max.</span>
            </InputGroup>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProxyConfigurationForm
