import React, { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react'

import styles from './CustomButton.module.scss'

import Loader from '../../progress/Loader'

export interface ICustomButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl'
  color?: 'blue' | 'outline' | 'red' | 'black' | 'ghost' | 'ghostAlt'
  disabled?: boolean
  loading?: boolean
  className?: string
  class?: string
  children?: string | ReactElement
  largeRadius?: boolean
  wide?: boolean
  icon?: ReactNode
}

const CustomButton: React.FC<ICustomButtonProps> = (props) => {
  const {
    disabled,
    onClick,
    size,
    color,
    children,
    className,
    loading,
    largeRadius,
    icon,
    wide,
    ...rest
  } = props

  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`
        ${styles.btn}
        ${styles[size || 'm']}
        ${styles[color || 'blue']} 
        ${!!disabled || !!loading ? styles.disabled : ''}
        ${!!loading ? styles.loading : ''}
        ${largeRadius ? styles.largeRadius : ''}
        ${!children ? styles.iconOnly : ''}
        ${wide ? styles.wide : ''}
        ${className || ''}
      `}
      {...rest}
    >
      {!!icon && (
        <div
          className={`
            ${styles.iconWrapper}
            ${!!children ? styles.margined : ''}
          `}
          data-testid="button-icon"
        >
          {icon}
        </div>
      )}

      <span className={styles.text}>{children}</span>

      {loading ? <Loader classname={styles.spinnerWrapper} /> : null}
    </button>
  )
}

export default CustomButton
