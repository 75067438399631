import React from 'react'

import styles from './PlanStatus.module.scss'

import { SubscriptionStatus } from 'models/user'

interface IPlanStatusProps {
  status: SubscriptionStatus
  className?: string
}

const statusLabels = {
  active: 'Active',
  trial: 'Trial',
  paused: 'Paused',
  expired: 'Expired',
  blocked: 'BLocked',
  cancelled: 'Cancelled',
  unpaid: 'Unpaid',
}

const PlanStatus: React.FC<IPlanStatusProps> = ({ status, className }) => {
  if (!status) return null

  return (
    <div
      className={`
        ${styles.status}
        ${styles[status]}
        ${className || ''}
      `}
    >
      {statusLabels[status] || ''}
    </div>
  )
}

export default PlanStatus
