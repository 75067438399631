import * as React from 'react'
import type { SVGProps } from 'react'
const SvgReviewG2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={107}
    height={59}
    fill="none"
    viewBox="0 0 107 59"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#101828"
        d="M38.492 20.35q-1.455 0-2.561-.562a4.15 4.15 0 0 1-1.708-1.63q-.602-1.049-.602-2.407v-4.696q0-1.379.602-2.407a4.03 4.03 0 0 1 1.708-1.61q1.105-.583 2.561-.583 1.437 0 2.523.563 1.107.563 1.708 1.533.602.97.602 2.193v.155a.24.24 0 0 1-.078.175.2.2 0 0 1-.155.058h-1.766a.3.3 0 0 1-.175-.058.3.3 0 0 1-.058-.175v-.097q0-1.047-.718-1.727-.699-.68-1.883-.68t-1.901.719q-.72.698-.719 1.863v4.852q0 1.164.757 1.882.757.699 1.96.699 1.165 0 1.825-.602.678-.621.679-1.688v-1.204q0-.097-.097-.097h-2.387a.3.3 0 0 1-.175-.058.3.3 0 0 1-.058-.175V13.15a.2.2 0 0 1 .058-.155.24.24 0 0 1 .175-.077h4.483q.096 0 .155.077.078.058.078.155v2.484q0 2.232-1.32 3.474-1.3 1.242-3.513 1.242m9.923-2.212q-.04.039-.04.078.02.038.079.038h5.53q.097 0 .156.078.077.058.078.155v1.475a.24.24 0 0 1-.078.175.2.2 0 0 1-.156.058H45.64a.3.3 0 0 1-.175-.058.3.3 0 0 1-.058-.175v-1.397q0-.175.116-.291a104 104 0 0 0 3.416-3.862l.912-1.068q1.785-2.076 1.785-3.143 0-.796-.562-1.3-.562-.505-1.456-.505-.892 0-1.456.504-.543.505-.543 1.34v.504a.24.24 0 0 1-.078.175.2.2 0 0 1-.155.058h-1.805a.3.3 0 0 1-.174-.058.3.3 0 0 1-.058-.175v-.835q.038-1.047.601-1.824.563-.775 1.514-1.203.97-.427 2.154-.427 1.3 0 2.27.485.972.485 1.495 1.34.524.834.524 1.882 0 1.59-1.688 3.668-.931 1.164-3.067 3.493z"
      />
      <path
        fill="#FF492C"
        d="M13.964 25.24c6.421 0 11.627-4.97 11.627-11.1s-5.206-11.1-11.627-11.1S2.336 8.01 2.336 14.14s5.206 11.1 11.628 11.1"
      />
      <path
        fill="#101828"
        d="M18.995 11.546h-1.987c.054-.298.247-.464.638-.653l.365-.178c.653-.32 1.002-.681 1.002-1.272q0-.556-.451-.874-.448-.316-1.068-.315a1.7 1.7 0 0 0-.904.246c-.272.16-.475.366-.6.624l.574.55c.224-.43.547-.641.972-.641.36 0 .582.177.582.424 0 .206-.107.377-.521.577l-.235.109c-.51.246-.863.528-1.067.848q-.306.479-.305 1.207v.133h3.004zm-.266 1.691h-3.288l-1.644 2.717h3.288l1.644 2.72 1.645-2.72z"
      />
      <path
        fill="#101828"
        d="M14.078 17.754c-2.093 0-3.798-1.627-3.798-3.625s1.705-3.625 3.798-3.625l1.3-2.595a7 7 0 0 0-1.3-.122c-3.67 0-6.644 2.84-6.644 6.342 0 3.503 2.974 6.342 6.644 6.342a6.8 6.8 0 0 0 3.911-1.216l-1.437-2.38a3.9 3.9 0 0 1-2.474.88"
      />
      <path
        fill="#FF492C"
        d="m86.99 45.575 5.396-.872 2.904-4.836.92 2.096 1.391 1.359.696 1.381v9.491l-3.09-1.642-5.062 2.379.664-5.47zm-82.916.159 5.395-.872 2.822-5.153 2.573 5.074 5.81.951-4.067 3.806.83 5.55-5.146-2.38-5.146 2.38.664-5.471zm20.667 0 5.395-.872 2.822-5.153 2.656 5.074 5.727.951-4.067 3.806.913 5.55-5.23-2.38-5.062 2.38.664-5.471zm21.082 0 5.395-.872 2.822-5.153 2.573 5.074 5.81.951-4.067 3.806.913 5.55-5.229-2.38-5.146 2.38.664-5.471zm20.418 0 5.395-.872 2.822-5.153 2.656 5.074 5.727.951-4.067 3.806.913 5.55-5.23-2.38-5.062 2.38.664-5.471z"
      />
      <path
        fill="#FF492C"
        d="m22.256 45.248-6.89-.951-3.07-5.946-3.071 5.946-6.89.951 4.98 4.598-1.161 6.58 6.142-3.091 6.142 3.092-1.162-6.58zm-9.96 6.66-4.316 2.22.83-4.599-3.486-3.25 4.897-.714 2.158-4.202 2.158 4.202 4.897.714-3.486 3.25.83 4.598zm30.71-6.66-6.89-.951-3.07-5.946-3.071 5.946-6.89.951 4.98 4.598-1.161 6.58 6.142-3.091 6.142 3.092-1.162-6.58zm-9.96 6.66-4.316 2.22.83-4.599-3.486-3.25 4.897-.714 2.158-4.202 2.158 4.202 4.897.714-3.486 3.25.83 4.598zm30.71-6.66-6.89-.951-3.07-5.946-3.071 5.946-6.89.951 4.98 4.598-1.161 6.58 6.142-3.091 6.142 3.092-1.163-6.58zm-9.96 6.66-4.316 2.22.83-4.599-3.486-3.25 4.897-.714 2.157-4.202 2.158 4.202 4.897.714-3.486 3.25.83 4.598zm30.71-6.66-6.89-.951-3.07-5.946-3.072 5.946-6.889.951 4.98 4.598-1.162 6.58 6.142-3.091 6.142 3.092-1.162-6.58zm-9.96 6.66-4.317 2.22.83-4.599-3.486-3.25 4.898-.714 2.157-4.202 2.158 4.202 4.897.714-3.486 3.25.83 4.598zm30.709-6.66-6.889-.951-3.07-5.946-3.072 5.946-6.889.951 4.98 4.598-1.162 6.58 6.142-3.091 6.142 3.092-1.162-6.58zm-9.96 6.66-4.316 2.22.83-4.599-3.486-3.25 4.897-.714 2.158-4.202 2.158 4.202 4.897.714-3.486 3.25.83 4.598z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h106.67v58.67H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgReviewG2
