import { IUserSubscription } from '../models/user'
import { workingSubscriptionStatuses } from './constants'

export const getCookie = (cookieName: string) => {
  let cookie: any = {}

  document.cookie.split(';').forEach((el: string) => {
    let [key, value] = el.split('=')

    cookie[key.trim()] = value
  })

  return cookie[cookieName]
}

export const capitalizeWord = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

export const getNormalizedLocation = (location: string) => {
  return location.replaceAll(' ', '%20')
}

export const getDeepTargetResult = (
  regionValue?: string,
  cityValue?: string,
  ispValue?: string
) => {
  let deepTargetResult: string = ''

  if (!!regionValue && regionValue !== 'any') {
    deepTargetResult =
      deepTargetResult + `-region-${getNormalizedLocation(regionValue)}`
  }

  if (!!cityValue && cityValue !== 'any') {
    deepTargetResult =
      deepTargetResult + `-city-${getNormalizedLocation(cityValue)}`
  }

  if (!!ispValue && ispValue !== 'any') {
    deepTargetResult =
      deepTargetResult + `-isp-${getNormalizedLocation(ispValue)}`
  }

  return deepTargetResult
}

export const getPriceWithoutCents = (price: string) => {
  return price ? price.substr(0, price.length - 3) : ''
}

export const getNormalizedPrice = (price: string | number) => {
  const formattedPrice = +price

  return formattedPrice.toFixed(2)
}

export const getMinimalPrice = (prices: string[] | null) => {
  if (!!prices) {
    const numbersArray = prices.map((price) => +price)
    // const normalizedArray = numbersArray.map((price) => +price.toFixed(2))

    return Math.min(...numbersArray)
  } else {
    return null
  }
}

export const isSubscriptionWorking = (subscription: IUserSubscription) => {
  if (
    !!subscription &&
    subscription.status &&
    workingSubscriptionStatuses.indexOf(subscription.status) >= 0
  ) {
    return true
  } else {
    return false
  }
}
