const name = 'pricingReducer'

export const selectPlansList = (state: any) => state[name].plans

export const selectPlansFetching = (state: any) => state[name].plansFetching

export const selectProductPrice = (state: any) => state[name].productPrice

export const selectProductPriceFetching = (state: any) =>
  state[name].productPriceFetching

export const selectSubscriptionProcessing = (state: any) =>
  state[name].subscriptionProcessing

export const selectInvoiceUrl = (state: any) => state[name].invoiceUrl

export const selectBillingModalVisibility = (state: any) =>
  state[name].isBillingFormShown

export const selectCouponValidationProcessing = (state: any) =>
  state[name].couponValidationProcessing

export const selectAppliedCouponInfo = (state: any) =>
  state[name].appliedCouponInfo

export const selectIsCouponInvalid = (state: any) => state[name].isCouponInvalid

export const selectTopUpModalVisibility = (state: any) =>
  state[name].isTopUpModalShown

export const selectTopUpBalanceProcessing = (state: any) =>
  state[name].topUpBalanceProcessing

export const selectCancelSubscriptionProcessing = (state: any) =>
  state[name].cancelSubscriptionProcessing

export const selectInvoiceModalStatus = (state: any) =>
  state[name].isInvoiceModalShown
