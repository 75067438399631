import { get, post, del } from 'utils/request'
import {
  ICouponValidationData,
  IInvoiceRequestData,
  IBillingInfoData,
  ITopUpBalanceData,
} from 'models/pricing'

export const getPlansList = async () => await get(`/api/plans`)

export const getProductPrice = async (data: { product_id_orb: string }) =>
  await post(`/api/product-price`, data)

export const createSubscription = async (data: IBillingInfoData) =>
  await post('/api/subscriptions', data)

export const validateCoupon = async (data: ICouponValidationData) =>
  await post('/api/coupon-validation', data)

export const getInvoiceUrl = async (params: IInvoiceRequestData) =>
  await get('/api/issued-invoice', { params })

export const topUpBalance = async (data: ITopUpBalanceData) =>
  await post('/api/top-up', data)

export const cancelAutoTopUp = async () => await del('/api/top-up')

export const cancelSubscription = async () => await del('/api/subscriptions')
