import React from 'react'
import { useSelector } from 'react-redux'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import { getNormalizedPrice } from 'utils/functions'

import { Check as CheckIcon, BookOpen as BookIcon } from '../../../../icons'
import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import InputGroup from 'components/elements/forms/InputGroup'
import CopyButton from 'components/elements/buttons/CopyButton'
import {
  selectProductPrice,
  selectProductPriceFetching,
} from '../../../../../store/pricing/selectors'

interface IProxyListResultProps {
  selectedProxyType: any
  resultArray: string[] | null
}

const ProxyListResult: React.FC<IProxyListResultProps> = (props) => {
  const { selectedProxyType, resultArray } = props

  const productPrice = useSelector(selectProductPrice)
  const productPriceFetching = useSelector(selectProductPriceFetching)

  /*const [outputMask, setOutputMask] = useState(
    '{login}:{password}@{server}:{port}{login}:{password}@{server}:{port}'
  )

  const handleOutputMaskChange = (e: any) => {
    setOutputMask(e.target.value)
  }*/

  /*const getListResult = () => {
    let listResult: string[] = []

    configurationItems.forEach((item) => {
      for (let i = 1; i <= item.connectionsCount; i++) {
        const deepTargetResult = getDeepTargetResult(
          item.location.region,
          item.location.city,
          item.location.isp
        )

        listResult.push(
          getProxyResultString(
            proxyListResultSample,
            selectedProxyType.value,
            item.location.country,
            deepTargetResult,
            item.sessionType,
            item.sessionLength.value,
            `${item.sessionPrefix}_x${i}`
          )
        )
      }
    })

    return listResult
  }*/

  const getProductName = () => {
    if (selectedProxyType && selectedProxyType.shortLabel) {
      return `Proxy: ${selectedProxyType.shortLabel} pool`
    } else {
      return ''
    }
  }

  const productName = getProductName()

  const normalizedPrice = getNormalizedPrice(productPrice)

  const productPriceString = productPrice ? `$${normalizedPrice} per 1 GB` : ''

  /*const handleTxtDownload = () => console.log('Txt')

  const handleCsvDownload = () => console.log('Csv')*/

  const resultList = resultArray?.map((result, index) => (
    <li className={styles.resultListItem} key={`result-list-item_${index}`}>
      <div className={styles.resultListItemNumber}>{index + 1}</div>
      <div>{result}</div>
    </li>
  ))

  /*const actionOptions = [
    {
      label: 'Txt',
      onClick: () => handleTxtDownload(),
    },
    {
      label: 'CSV',
      onClick: () => handleCsvDownload(),
    },
  ]*/

  return (
    <>
      <div className={styles.backgroundBlock} />

      <div className={styles.blockHeader}>
        <span className={styles.blockTitle}>Result</span>

        <div>
          <div className={styles.checkIconWrapper}>
            <CheckIcon />
          </div>
          Ready
        </div>
      </div>

      <ProductPricingBlock
        className={styles.pricingBlock}
        product={productName}
        price={productPriceString}
        loading={productPriceFetching}
      />

      {/*<Accordion title={'Edit output mask'} className={styles.maskAcc}>
        <CustomInput value={outputMask} onChange={handleOutputMaskChange} />
      </Accordion>*/}

      <div className={styles.resultMain}>
        <InputGroup labelTop={'Proxy list'} className={styles.resultInputGroup}>
          <div className={styles.resultBlock}>
            <ol className={styles.resultList}>{resultList}</ol>
          </div>
        </InputGroup>

        <div className={styles.resultControls}>
          <CopyButton
            className={styles.resultControlBtn}
            text={!!resultArray ? resultArray.join('\n') : ''}
          >
            Copy text
          </CopyButton>

          {/*<CopyButton
            className={styles.resultControlBtn}
            color={'outline'}
            text={'link'}
          >
            Copy link
          </CopyButton>

          <ComboButton
            className={styles.resultControlBtn}
            actionOptions={actionOptions}
          >
            Download
          </ComboButton>*/}
        </div>
      </div>

      <a
        className={styles.guideLink}
        href="https://helpcenter.soax.com/en/articles/9925411-how-to-use-a-proxy-list"
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        <div className={styles.bookIconWrapper}>
          <BookIcon />
        </div>{' '}
        <span>Guide: how to use proxy list</span>
      </a>
    </>
  )
}

export default ProxyListResult
