import React, { useCallback } from 'react'
import { Tooltip } from 'react-tooltip'

import { ISubscriptionCardProps } from 'models/pricing'

import styles from './SubscriptionCard.module.scss'

import CustomButton from 'components/elements/buttons/CustomButton'
import { InfoCircle as InfoIcon, Bomb as BombIcon } from '../../../icons'

import {
  getPriceWithoutCents,
  getMinimalPrice,
  getNormalizedPrice,
} from 'utils/functions'
import { ICustomButtonProps } from 'components/elements/buttons/CustomButton/CustomButton'

const SubscriptionCard: React.FC<ISubscriptionCardProps> = (props) => {
  const {
    plan,
    className,
    isTrialActual,
    subscriptionProcessing,
    planProcessing,
    promoLabel,
    altPromoLabel,
    altPromoDescription,
    isCustomPlan,
    onPlanClick,
    activePriceType,
    activeSubscriptionPlanId,
  } = props

  const isTrialAvailableForPlan =
    !!plan.trial_price && plan.type && plan.type !== 'payg'

  const handleClick = useCallback(() => {
    onPlanClick(plan)
  }, [onPlanClick, plan])

  const getPlanPrice = () => {
    if (plan.type === 'payg') {
      return '15'
    } else if (isCustomPlan) {
      return plan.amount.monthly
    } else if (activePriceType) {
      return getPriceWithoutCents(plan?.amount[activePriceType])
    }
  }

  const planPrice = getPlanPrice()

  const renderButton = useCallback(() => {
    let btnLabel: string
    let btnColor: ICustomButtonProps['color'] = 'blue'
    let isPermanentlyDisabled: boolean = false

    if (plan.id === activeSubscriptionPlanId) {
      btnLabel = 'Current Plan'
      isPermanentlyDisabled = true
    } else if (isCustomPlan) {
      btnColor = 'outline'
      btnLabel = 'Contact Sales'
    } else if (isTrialAvailableForPlan) {
      btnLabel = plan.trial_price
        ? `Try for $${plan.trial_price}`
        : 'Get Free Trial'
    } else {
      btnLabel = 'Subscribe'
    }

    if (!!altPromoLabel) {
      btnColor = 'black'
    }

    return (
      <CustomButton
        className={`
            ${styles.btn}
            ${!!altPromoLabel ? styles.altHighlighted : ''}
          `}
        onClick={handleClick}
        loading={planProcessing}
        disabled={subscriptionProcessing || isPermanentlyDisabled}
        largeRadius={true}
        size={'l'}
        color={btnColor}
      >
        {btnLabel}
      </CustomButton>
    )
  }, [
    plan.id,
    plan.trial_price,
    activeSubscriptionPlanId,
    isCustomPlan,
    isTrialAvailableForPlan,
    promoLabel,
    handleClick,
    planProcessing,
    subscriptionProcessing,
  ])

  const renderHighlight = useCallback(() => {
    if (!!promoLabel) {
      return <div className={styles.highlight}>{promoLabel}</div>
    } else if (!!altPromoLabel) {
      return (
        <>
          <div
            className={styles.highlightAlt}
            data-tip
            data-tooltip-id={`alt_promo_description`}
          >
            {altPromoLabel}&nbsp;
            <InfoIcon />
          </div>
          <Tooltip id={`alt_promo_description`} place={'bottom-end'}>
            {altPromoDescription}
          </Tooltip>
        </>
      )
    } else {
      return null
    }
  }, [altPromoDescription, altPromoLabel, promoLabel])

  const renderTrialNotice = () => {
    if (isTrialActual) {
      if (plan.type === 'payg') {
        return <div className={styles.trialNotice}>First payment $15</div>
      } else {
        return (
          <div
            className={styles.trialNotice}
          >{`3-day Trial, then $${planPrice}/mo`}</div>
        )
      }
    } else {
      return null
    }
  }

  const minProxyPrice = getMinimalPrice(
    Object.values(plan.prices.monthly.proxy)
  )

  const ecommercePrices = plan.prices.monthly.ecommerce
    ? Object.values(plan.prices.monthly.ecommerce)
    : []

  const serpPrices = plan.prices.monthly.serp
    ? Object.values(plan.prices.monthly.serp)
    : []

  const socialPrices = plan.prices.monthly.social
    ? Object.values(plan.prices.monthly.social)
    : []

  const unblockerPrices = plan.prices.monthly.unblocker
    ? Object.values(plan.prices.monthly.unblocker)
    : []

  const scrapingPricesArray = ecommercePrices.concat(
    serpPrices,
    socialPrices,
    unblockerPrices
  )

  // @ts-ignore
  const minScrapingPrice = getMinimalPrice(scrapingPricesArray)

  const normalizedMinProxyPrice = minProxyPrice
    ? getNormalizedPrice(minProxyPrice.toString())
    : ''
  const normalizedMinScrapingPrice = minScrapingPrice
    ? getNormalizedPrice(minScrapingPrice.toString())
    : ''

  return (
    <div
      className={`
        ${styles.container}
        ${className || ''}
      `}
    >
      <div
        className={`
          ${styles.content}
          ${!!promoLabel ? styles.highlighted : ''}
          ${!!altPromoLabel ? styles.highlightedAlt : ''}
        `}
      >
        {renderHighlight()}

        <div className={styles.price}>
          <div className={styles.priceMain}>${planPrice}</div>

          <div className={styles.priceLabel}>
            {plan.type === 'payg' ? (
              <>
                <p>starting</p>
                <p>from</p>
              </>
            ) : (
              <>/mo.</>
            )}
          </div>
        </div>

        <div className={styles.name}>
          <div className={!!promoLabel ? styles.highlighted : ''}>
            {plan.name}
          </div>

          {!!altPromoLabel && (
            <div className={styles.bombWrapper}>
              <BombIcon />
            </div>
          )}
        </div>

        <div className={styles.info}>
          <div className={styles.infoBlock}>
            <div>API</div>

            <div>from ${normalizedMinScrapingPrice}</div>
          </div>

          <div className={styles.infoBlock}>
            <div>Proxy</div>

            <div>from ${normalizedMinProxyPrice}</div>
          </div>
        </div>

        <div className={styles.additionalInfo}>{renderButton()}</div>

        {renderTrialNotice()}
      </div>
    </div>
  )
}

export default SubscriptionCard
