import React, { useState } from 'react'

import styles from '../../ProxyContent/ProxyContent.module.scss'

import { getNormalizedPrice } from 'utils/functions'

import { Check as CheckIcon, BookOpen as BookIcon } from '../../../../icons'
import ProductPricingBlock from 'components/elements/blocks/ProductPricingBlock'
import TabsList from 'components/elements/tabs/TabsList'
import CurlResult from './CurlResult'
import CredentialsResult from './CredentialsResult'
import UrlResult from './UrlResult'
import TabPanels from 'components/elements/tabs/TabPanels'
import { IProxyPackage } from '../../../../../models/user'

import {
  selectProductPrice,
  selectProductPriceFetching,
} from 'store/pricing/selectors'
import { useSelector } from 'react-redux'

interface IProxyConnectResultProps {
  selectedProxyType: any
  selectedPackage: IProxyPackage
  urlResultString: string
  credResultString: string
  curlResultString: string
}

const ProxyQuickResult: React.FC<IProxyConnectResultProps> = (props) => {
  const {
    selectedProxyType,
    selectedPackage,
    urlResultString,
    credResultString,
    curlResultString,
  } = props

  const tabItems = [
    {
      label: 'cURL request',
    },
    {
      label: 'Credentials',
    },
    {
      label: 'URL',
    },
  ]

  const [selectedResultTypeIndex, setSelectedResultTypeIndex] = useState(0)

  const productPrice = useSelector(selectProductPrice)
  const productPriceFetching = useSelector(selectProductPriceFetching)

  const getProductName = () => {
    if (selectedProxyType && selectedProxyType.shortLabel) {
      return `Proxy: ${selectedProxyType.shortLabel} pool`
    } else {
      return ''
    }
  }

  const productName = getProductName()

  const normalizedPrice = getNormalizedPrice(productPrice)

  const productPriceString = productPrice ? `$${normalizedPrice} per 1 GB` : ''

  const handleResultTypeChange = (index: number) => {
    setSelectedResultTypeIndex(index)
  }

  const credPassword = selectedPackage.package_key
  const credServer = 'proxy.soax.com'
  const credPort = '5000'

  const tabContentArray = [
    <CurlResult result={curlResultString} />,
    <CredentialsResult
      login={credResultString}
      password={credPassword}
      server={credServer}
      port={credPort}
    />,
    <UrlResult result={urlResultString} />,
  ]

  return (
    <>
      <div className={styles.backgroundBlock} />

      <div className={styles.blockHeader}>
        <span className={styles.blockTitle}>Result</span>

        <div>
          <div className={styles.checkIconWrapper}>
            <CheckIcon />
          </div>
          Ready
        </div>
      </div>

      <ProductPricingBlock
        className={styles.pricingBlock}
        product={productName}
        price={productPriceString}
        loading={productPriceFetching}
      />

      <div className={styles.resultContent}>
        <div className={styles.resultTabsWrapper}>
          <TabsList
            items={tabItems}
            onChange={handleResultTypeChange}
            activeTabIndex={selectedResultTypeIndex}
          />
        </div>

        <TabPanels
          className={styles.resultMain}
          items={tabContentArray}
          activeTabIndex={selectedResultTypeIndex}
        />

        <div className={styles.resultNotice}>
          To get new IP address with same configuration, change Session ID (step
          3)
        </div>

        <a
          className={styles.guideLink}
          href="https://helpcenter.soax.com/en/articles/9925415-building-sticky-sessions-connection"
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <div className={styles.bookIconWrapper}>
            <BookIcon />
          </div>{' '}
          <span>Guide: Building sticky sessions connection</span>
        </a>
      </div>
    </>
  )
}

export default ProxyQuickResult
