import React from 'react'
import { useSelector } from 'react-redux'

import MainHeader from 'components/navigation/MainHeader'
import ScrapingContent from 'components/modules/scraping/ScrapingContent'
import SubscriptionInfo from 'components/modules/settings/SubscriptionsInfo'
import { isSubscriptionWorking } from 'utils/functions'

import { selectSubscriptionInfo } from 'store/user/selectors'

const ScrapingPage: React.FC = () => {
  const crumbsItems = [
    {
      label: 'My products',
      to: '/',
    },
    {
      label: 'Scraping APIs',
      isActive: true,
    },
  ]

  const subscriptionInfo = useSelector(selectSubscriptionInfo)

  return (
    <>
      <MainHeader crumbsItems={crumbsItems} />
      {isSubscriptionWorking(subscriptionInfo) ? (
        <ScrapingContent />
      ) : (
        <SubscriptionInfo reducedDisplayMode />
      )}
    </>
  )
}

export default ScrapingPage
